<template>
    <div>
        <li v-if="icon" :id="deviceId !== '' ? deviceId : name" class="nav-item nav-dropdown">
            <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                <fa-icon v-if="this.icon" :icon="this.icon" class="nav-icon" style="color: #a0a0a0;"/>{{ name }}
            </div>
            <ul class="nav-dropdown-items">
                <slot></slot>
            </ul>
        </li>
        <!-- this handles all the names of devices for the dropdown -->
        <li v-else :id="deviceId !== '' ? deviceId : name" class="nav-item nav-dropdown" style="background-color: inherit" :style="`font-size: ${fontSize}px;`">
            <div class="d-flex align-items-center rounded highlightEle" style="cursor: pointer;" @click="handleClick">
                <!-- this one needs to stay as an icon so we can update the folder as open or closed using the id -->
                <i :id="(deviceId !== '' ? deviceId : name) + 'icon'" class="fas fa-folder" style="color: #ffffb5"/>&nbsp;
                <OverflowAutoScroll :text="name" :deviceId="deviceId" :fontSize="fontSize" :width="role === 'device' ? '145px' : '165px'"/>
            </div>
            <ul class="nav-dropdown-items">
                <slot></slot>
            </ul>
        </li>
    </div>

</template>

<script>
import OverflowAutoScroll from "@/components/OverflowAutoScroll.vue";
export default {
    props: {
        name: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        deviceId: {
            type: String,
            default: ''
        },
        fontSize: {
            type: Number,
            default: 14
        },
        role: {
            //this value lets me know if the name is of an edge device or just a camera
            type: String,
            default: 'device'
        }
    },
    components: {
        OverflowAutoScroll
    },
    data() {
        return {
            isHovered: false
        }
    },
    mounted() {
        if (this.name === 'Video Wall') {
            document.getElementById('Video Wall').classList.toggle('open');
        }
    },
    methods: {
        handleClick(e) {
            this.$emit('dropDownClicked');
            if (this.icon) {
                e.preventDefault();
                document.getElementById(this.deviceId !== '' ? this.deviceId : this.name).classList.toggle('open');
            } else {
                e.preventDefault();
                document.getElementById((this.deviceId !== '' ? this.deviceId : this.name)).firstElementChild.classList.toggle('open');
                let element2 = document.getElementById((this.deviceId !== '' ? this.deviceId : this.name) + 'icon').classList;
                if (document.getElementById((this.deviceId !== '' ? this.deviceId : this.name) + 'icon').classList.contains('fa-folder')) {
                    element2.remove('fa-folder');
                    element2.add('fa-folder-open');
                } else {
                    element2.add('fa-folder');
                    element2.remove('fa-folder-open');
                }
            }
        }
    },
    computed: {
        classIcon() {
            return [
                'nav-icon',
                this.icon
            ];
        }
    },
}
</script>

<style scoped lang="css">
.nav-link {
    cursor: pointer;
}

.sidebar .nav-dropdown.open > .nav-dropdown-items {
    max-height: none;
}

.sidebar-minimized .sidebar .nav > .nav-dropdown.open > .nav-dropdown-items {
    max-height: none;
}
.highlightEle:hover {
    background-color: #595959;
}
</style>
