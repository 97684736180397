<template>
    <div>

        <!-- Main -->
        <div class="w-100 d-flex align-items-center justify-content-center">
            <b-button variant="primary" size="sm" title="Video Search" @click="openSearchModal">
                Search for Object
            </b-button>
        </div>

        <!-- Smart Search Modal -->
        <b-modal v-model="smartSuiteVideSearchModal" title="Smart Search" no-close-on-backdrop size="xl" @close="resetSearchVars">

            <!-- Body -->
            <div>

                <!-- Search Fields -->
                <div>
                    <date-range-select :stacked="false" :noFuture="true" :startDate="searchParams.startDate" :endDate="searchParams.endDate"
                                       @startDate="startDateSelected" @endDate="endDateSelected" @isValid="isValidDate"/>
                </div>
                <div style="margin: 15px 0;">
                    <div style="font-size: 16px;"><b>Searchable Objects:</b></div>
                    <Multiselect v-model="searchParams.selectedObject"
                                 :options="searchableObjects"
                                 :multiple="false"
                                 label="label"
                                 track-by="label"/>
                </div>

                <!-- Searching and results are found -->
                <div class="d-flex justify-content-between">
                    <div>
                        <div v-if="searching === true" >
                            <span style="font-size: 16px;"><b>Searching Progress </b></span>
                            <fa-icon :icon="['fas', 'spinner']" spin/>
                        </div>
                        <div v-else-if="searchComplete === true" style="font-size: 16px;"><b>Search Results: </b></div>
                        <div v-else style="padding-bottom: 24px;"/>
                    </div>
                    <div v-if="searching === true || searchComplete === true || foundEvents.length > 0">
                        <fa-icon :icon="['fas', 'angle-left']" style="font-size: 22px;"
                                 :style="imageIndex === 0 ? 'color: #1a1a1a; cursor: default;' : ''" @click="previousImage"/>
                        <span style="font-size: 14px; position: relative; bottom: 3px; padding: 0 5px;"> {{(imageIndex+1)+'/'+foundEvents.length}} </span>
                        <fa-icon :icon="['fas', 'angle-right']" style="font-size: 22px;"
                                 :style="imageIndex === foundEvents.length - 1 || foundEvents.length === 0 ? 'color: #1a1a1a; cursor: default;' : ''"
                                 @click="nextImage"/>
                    </div>
                </div>
                <div v-if="searching === true" style="padding-bottom: 8px;" >
                    <b-progress :value="searchedSegments" :max="segmentTotal" animated show-progress/>
                </div>
                <div v-else style="padding-bottom: 24px;"/>

                <!-- No Results -->
                <div v-if="searching === false && searchComplete === true && foundEvents.length === 0">
                    <div class="d-flex justify-content-center rounded"
                         style="font-size: 24px; min-height: 620px; padding-top: 280px; background-color: black;">
                        <b><span>No Results Found</span></b>
                    </div>
                </div>

                <!-- Results -->
                <div v-if="foundEvents.length > 0">
                    <div class="d-flex" style="overflow-x: hidden" id="imageList">
                        <div v-for="(event, index) in foundEvents" style="padding-right: 5px;">
                            <div v-if="index === imageIndex - 1 || index === imageIndex || index === imageIndex + 1">
                                <div style="font-size: 12px;"><b>Time Stamp: {{ new Date(parseInt(event.results[0].segmentKey.requestedSegment)).toLocaleString() }}</b></div>
                                <img :id="'imageKey'+event.results[0].segmentKey.deviceStoreId+event.results[0].segmentKey.requestedSegment" :src="'data:image/png;base64,'+event.results[0].resultSnapShot" width="1101" height="620" @click="goToTimeStamp(event, index)" alt=""/>
                            </div>
                            <div v-else>
                                <div style="min-width: 1101px;"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Footer -->
            <template slot="modal-footer">
                <div class="d-flex justify-content-between w-100">
                    <div class="d-flex" style="gap: 10px;">
                        <b-button size="sm" variant="primary" @click="goToTimeStamp(foundEvents[imageIndex], imageIndex)" :disabled="foundEvents.length === 0">View Footage</b-button>
                        <b-button size="sm" variant="primary" @click="snapShot(imageIndex)" :disabled="foundEvents.length === 0">Save Image</b-button>
                    </div>
                    <div class="d-flex" style="gap: 10px;">
                        <b-button size="sm" variant="secondary" @click="resetSearchVars">Close</b-button>
                        <b-button v-if="searching === false" size="sm" variant="primary" @click="searchForObjects" :disabled="checkValidTimeAndObject()">Search</b-button>
                        <b-button v-else size="sm" variant="danger" @click="cancelSearchJob">Cancel Search</b-button>
                    </div>
                </div>
            </template>

        </b-modal>

    </div>
</template>

<script>
import {searchableObjects} from "@/shared/smart_search_list";
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Multiselect from 'vue-multiselect';
import dateRangeSelect from "@/components/date-range-select.vue";
import PubSub from "pubsub-js";
import * as moment from "moment/moment";

export default {
    name: "SmartSuiteVideoSearch",
    props: {
        devices: {
            type: Array,
            default() {
                return []
            }
        },
        sourceToken: {
            type: String,
            default: undefined
        },
        dataChannel: {
            type: String,
            default: undefined
        }
    },
    components: {
        datePicker,
        Multiselect,
        dateRangeSelect
    },
    data() {
        return {
            smartSuiteVideSearchModal: false,
            searchableObjects,
            searchParams: {
                startDate: null,
                endDate: null,
                selectedObject: null,
            },
            startDate: null,
            endDate: null,
            isValid: false,
            pubsubs: [],
            createdPubSubs: false,
            setListener: false,
            searchComplete: false,
            searching: false,
            imageIndex: 0,
            foundEvents: [],
            searchedSegments: 0,
            segmentTotal: 0,
            jobIds: [],
        }
    },
    created() {
        this.pubsubs.push(PubSub.subscribe('timeRangeSelection', async (msg, data) => {
            this.searchParams.startDate = moment(data.start).format('MM/DD/YYYY h:mm:ss a ');
            this.searchParams.endDate = moment(data.end).format('MM/DD/YYYY h:mm:ss a ');
        }));
        window.addEventListener('beforeunload', () => {
            this.cancelSearchJob();
        })
    },
    async beforeDestroy() {
        await this.cancelSearchJob();
        this.pubsubs.forEach(sub => {
            PubSub.unsubscribe(sub);
        });
        this.devices.forEach(device => {
            device.closeAnalyticsConnection(device.analyticsId)
        })
    },
    methods: {
        cancelSearchJob() {
              this.devices.forEach((device, index) => {
                  device.cancelSearchJob(device.analyticsId, this.jobIds[index]);
              });
              this.searching = false;
        },
        snapShot(index) {
            let deviceId = this.foundEvents[index].results[0].segmentKey.deviceStoreId.split('-')
            deviceId[0] = deviceId[0].toUpperCase();
            let device = this.devices.find(device => {
                return device.getDeviceId() === deviceId[0]
            })
            if (device !== undefined) {
                let title
                if (device.getMultiSensor() > 1) {
                    title = device.getMultiSensorStreams().find(_ => _.sourceToken === this.sourceToken).friendlyName
                } else {
                    title = device.getDeviceName()
                }
                let image = document.getElementById('imageKey'+this.foundEvents[index].results[0].segmentKey.deviceStoreId+this.foundEvents[index].results[0].segmentKey.requestedSegment);
                let canvas = this.capture(image, 1);
                let link = document.getElementById('link');
                link.setAttribute('download', title+' '+new Date(parseInt(this.foundEvents[index].results[0].segmentKey.requestedSegment)).toLocaleString()+'.png');
                link.setAttribute('href', canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
                link.click();
            }
        },
        capture(image, scaleFactor) {
            if (scaleFactor === null) {
                scaleFactor = 1;
            }
            let w = image.width * scaleFactor;
            let h = image.height * scaleFactor;
            let canvas = document.createElement('canvas');
            canvas.setAttribute("style", "z-index: 2;")
            canvas.width = w;
            canvas.height = h;
            let ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, w, h);
            return canvas;
        },
        startDateSelected(date) {
            this.searchParams.startDate = date;
        },
        endDateSelected(date) {
            this.searchParams.endDate = date;
        },
        isValidDate(boolean) {
            this.isValid = !boolean;
        },
        openSearchModal() {
            this.smartSuiteVideSearchModal = true;
        },
        previousImage() {
            if (this.imageIndex > 0 && this.foundEvents.length > 0) {
                this.imageIndex--;
                let imageList = document.getElementById('imageList')
                let count = 0
                let interval = setInterval(() => {
                    imageList.scrollLeft -= 55;
                    count++;
                    if (count === 19) {
                        imageList.scrollLeft = 1106*(this.imageIndex);
                        clearInterval(interval)
                    }
                }, 13)
            }
        },
        nextImage() {
            if (this.imageIndex < this.foundEvents.length-1) {
                this.imageIndex++;
                let imageList = document.getElementById('imageList')
                let count = 0
                let interval = setInterval(() => {
                    imageList.scrollLeft += 55;
                    count++;
                    if (count === 19) {
                        imageList.scrollLeft = 1106*(this.imageIndex);
                        clearInterval(interval)
                    }
                }, 13)
            }
        },
        resetSearchVars() {
            this.smartSuiteVideSearchModal = false;
            this.searchComplete = false;
        },
        goToTimeStamp(event, index) {
            this.smartSuiteVideSearchModal = false;
            let formattedEvents = this.foundEvents.map(event => {
                return {
                    dayCode: parseInt(event.results[0].segmentKey.dayCode),
                    hourCode: parseInt(event.results[0].segmentKey.hourCode),
                    segment: parseInt(event.results[0].segmentKey.requestedSegment),
                    endTime: parseInt(event.results[0].segmentKey.requestedSegment) + 20*1000,
                    storeName: event.results[0].segmentKey.deviceStoreId,
                }
            });
            PubSub.publish("timeRangeSpecific", {times: formattedEvents, currentTime: parseInt(event.results[0].segmentKey.requestedSegment), index: index});
        },
        searchForObjects() {
            this.searchedSegments = 0;
            this.segmentTotal = 0;
            this.imageIndex = 0;
            this.searchComplete = false;
            this.searching = true;
            this.foundEvents = [];
            let count = 0;
            this.devices.forEach(async (device, index) => {
                let analyticsId = await device.createAnalytics(device, this.sourceToken, this.dataChannel);
                let archiveId = await device.createArchive(device, this.sourceToken, this.dataChannel);
                device.analyticsId = analyticsId;
                device.archiveId = archiveId;
                count++;
                if (this.createdPubSubs === false) {
                    this.pubsubs.push(PubSub.subscribe('analyticsConnected' + archiveId, (msg, data) => {
                        device.findArchivedSegments(archiveId, this.searchParams.startDate, this.searchParams.endDate, segments => {
                            device.findAnalyticsSegments(analyticsId, segments, this.searchParams.selectedObject.value);
                        })
                    }))
                }
                if (count === this.devices.length) {
                    this.createdPubSubs = true;
                    for (const device2 of this.devices) {
                        await device2.connectToArchive(device2.archiveId);
                        await device2.connectToAnalytics(device2.analyticsId);
                        if (this.setListener === false) {
                            this.setListener = true;
                            await device2.analyticsResponseListener(device2.analyticsId, (event) => {
                                this.searchedSegments = event.segmentsComplete;
                                this.segmentTotal = event.segmentsRequested;
                                if (event.analyticsResponseType === 2) {
                                    this.foundEvents.push(event)
                                    this.jobIds[index] = event.jobId;
                                }
                                if (event.analyticsResponseType === 3) {
                                    this.searchComplete = true;
                                    this.searching = false;
                                }
                            });
                        }
                    }
                }
            });
        },
        checkValidTimeAndObject() {
            //if no object type is selected
            if (this.searchParams.selectedObject === null) {
                return true;
            } else if (this.isValid === false) {
                return true;
            }
            return false;
        },
    },

}
</script>

<style scoped>
</style>