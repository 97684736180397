<template>
    <div :style="`max-width: ${width}; font-size: ${fontSize}px;`">
        <div v-if="!scroll" :ref="'OverflowAuto-' + deviceId" style="overflow-x: hidden; white-space: nowrap;"
             :style="'max-width: ' + width">
            {{ text }}
        </div>
        <div v-else class="marquee">
            <div>{{ text }}</div>
            <div>{{ text }}</div>
        </div>
    </div>
</template>

<script>

import DeviceScreenShotPopup from "./deviceScreenShotPopup.vue";
    export default {
        name: "OverflowAutoScroll",
        props: {
            deviceId: {
                type: String,
                default: ''
            },
            text: {
                type: String,
                default: ''
            },
            width: {
                type: String,
                default: 'auto'
            },
            fontSize: {
                type: Number,
                default: 14
            }
        },
        components: {
          DeviceScreenShotPopup
        },
        data() {
            return {
                scroll: false
            }
        },
        created() {
            this.$nextTick(() => {
                let ref = this.$refs['OverflowAuto-' + this.deviceId] || false;
                if (ref.offsetWidth < ref.scrollWidth) {
                    this.scroll = true;
                }
            })
        },
    }
</script>

<style scoped>
    .marquee {
        width: inherit;
        white-space: nowrap;
        overflow-x: hidden;
        display: flex;
    }
    .marquee:not(:hover) div {
        width: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }
    .marquee:not(:hover) div:last-child {
        display: none;
    }
    .marquee:hover div {
        display: inline-block;
        margin-right: 10px;
        animation: marqueeFirst 5s linear, 5s marquee 5s linear infinite;
    }
    @keyframes marqueeFirst {
        10%   { transform: translateX(0); }
        100% { transform: translateX(-100%); }
    }
    @keyframes marquee {
        0%   { transform: translateX(10px); }
        100% { transform: translateX(-100%); }
    }
</style>
