<template>
    <div style="margin-right: -6px;" class="d-flex">
        <div :style="`color: ${videoColor};`">
            <fa-icon :icon="['fas', 'video']"/>
        </div>
        <div :style="`color: ${questionColor}; font-size: ${fontSize*2/3}px; transform: translate(-${fontSize-1}px, ${(fontSize/2)-(fontSize/4)}px);`">
            <fa-icon :icon="['fas', 'question']"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "VideoQuestionIcon",
    props: {
        fontSize: {
            Type: Number,
            default: 14
        },
        videoColor: {
            Type: String,
            required: true
        },
        questionColor: {
            Type: String,
            required: true
        }
    }
}
</script>

<style scoped>

</style>