<template>
    <a :class="classList" v-bind="attributes" tabindex="-1" v-on:click.stop.prevent v-if="isDisabled">
        <fa-icon :icon="this.icon" class="nav-icon"/> <span>{{ name }}</span>
        <b-badge v-if="badge && badge.text" :variant="badge.variant">{{ badge.text }}</b-badge>
    </a>
    <div :class="classList" v-bind="attributes" tabindex="-1" v-else-if="purpose==='videoWallNoCameras' || purpose === 'tempLoading'">
        <div style="cursor:default">
            <strong class="text-danger">{{ name }}</strong>
        </div>
        <b-tooltip v-if="tooltipRender && target" ref="tooltip" :target="target" :title="tooltipTitle" triggers=""/>
    </div>
    <div :class="classList" v-bind="attributes" tabindex="-1" v-else-if="purpose==='loading'">
        <div style="cursor:default" class="d-flex justify-content-between">
            <strong style="color: grey">{{ name }}</strong>
            <span>
                <fa-icon :icon="['fas', 'circle-notch']"/>
            </span>
        </div>
        <b-tooltip placement="bottomleft" v-if="tooltipRender && target" ref="tooltip" :target="target" :title="tooltipTitle" triggers=""/>
    </div>

    <!-- Video Wall Sidebar -->
    <div v-bind="attributes" tabindex="-1" :id="'link-' + curObject.getDeviceId()"
         v-else-if="purpose==='videoWall'" style="color: white; width: 100%;">

        <!-- If the device is online and up-to-date -->
        <div v-if="!reset">

            <!-- Build Video Wall Camera Tiles -->
            <!-- If the current device is a multisensor or a 4308 device -->
            <div v-if="curObject.getMultiSensor() > 1 || curObject.getDeviceModel().includes('4308')">

                <!-- Multi-Sensor Or Panoramic Devices-->
                <SidebarNavDropdown :id="curObject.getDeviceId()" :key="curObject.getDeviceId()" :name="curObject.getDeviceName()" :deviceId="curObject.getDeviceId()" :url="url" :icon="icon" :fontSize="fontSize">
                    <li style="cursor:pointer; margin-left: 5px;" :key="index2+'_multisensor'" class="nav-item d-flex"
                        v-for="(stream, index2) in curObject.getMultiSensor() > 1 ? curObject.getMultiSensorStreams(globalQuality) : curObject.getSingleSensorStreams(globalQuality, true)">
                        <!-- Bars used to show file tree for a device -->
                        <div style="position: relative">
                            <div v-if="curObject.getMultiSensor() > 1 ? index2 + 1 !== curObject.getMultiSensorStreams(globalQuality).length :  index2 + 1 !== curObject.getSingleSensorStreams(globalQuality, true).length"
                                 style="border-left: 1px solid white;" :style="`height: ${fontSize+Math.ceil(fontSize/2)}px;`"/>
                            <div v-else style="border-left: 1px solid white;" :style="`height: ${fontSize-Math.floor(fontSize/4)}px;`"/>
                        </div>
                        <div style="border-top: 1px solid white; width: 10px;" :style="`transform: translate(0, ${fontSize-Math.ceil(fontSize/4)}px)`"/>&nbsp;

                        <!-- MultiSensor Camera Up -->
                        <DeviceScreenShotPopup v-if="curObject.getMultiSensor() > 1" :dataChannel="stream.dataChannel" :device="curObject" :sourceToken="stream.sourceToken" style="width: 100%">
                            <div  @click="showHideCamera($event, curObject, stream.sourceToken, stream.dataChannel)" :key="'multi_sensor_'+updateKey+stream.dataChannel"
                                  :id="'preview-' + curObject.getDeviceId()" class="d-flex align-items-center highlightEle rounded"
                                  :draggable="isDraggable(curObject, stream.sourceToken)" @dragstart="dragCamera($event, curObject, stream.sourceToken, stream.dataChannel)" style="user-select: none;" @dragover.prevent>
                                <span v-if="currentCameras.findIndex(_ => _.ssDevice.getDeviceId() === curObject.getDeviceId() && _.sourceToken === stream.sourceToken) !== -1">

                                    <!-- if the multisensor stream is being displayed -->
                                    <span v-if="!curObject.getDeviceModel().includes('4308')">
                                        <fa-icon v-if="curObject.getDetectionStatus() === 0" :icon="['fas', 'video']"
                                                 style="color: white;"/>
                                        <VideoQuestionIcon v-else videoColor="#ffc107" questionColor="black" :fontSize="fontSize"/>
                                    </span>

                                    <span v-else>
                                        <span v-if="currentCameras.findIndex(_ => _.dataChannel === stream.dataChannel) !== -1">
                                            <fa-icon v-if="curObject.getDetectionStatus() === 0" :icon="['fas', 'video']"
                                                     style="color: white;"/>
                                            <VideoQuestionIcon v-else videoColor="#ffc107" questionColor="black" :fontSize="fontSize"/>
                                        </span>
                                        <span v-else>
                                            <fa-icon v-if="curObject.getDetectionStatus() === 0" :icon="['fas', 'video']"
                                                     style="color: #2a2a2a;"/>
                                            <VideoQuestionIcon v-else videoColor="#2a2a2a" questionColor="#ffc107" :fontSize="fontSize"/>
                                        </span>
                                    </span>
                                </span>
                                <span v-else>
                                    <fa-icon v-if="curObject.getDetectionStatus() === 0" :icon="['fas', 'video']"
                                             style="color: #2a2a2a;"/>
                                    <VideoQuestionIcon v-else videoColor="#2a2a2a" questionColor="#ffc107" :fontSize="fontSize"/>
                                </span>&nbsp;
                                <OverflowAutoScroll v-if="curObject" :text="stream.friendlyName" :deviceId="curObject.getDeviceId()" :fontSize="fontSize"
                                                    :style="'color: ' + (curObject.getDetectionStatus() === 0 ? 'inherit' : '#ffc107')" width="130px"/>
                            </div>
                        </DeviceScreenShotPopup>
                    </li>
                    <b-tooltip placement="bottomleft" v-if="tooltipRender && target" ref="tooltip" :target="target" :title="tooltipTitle" triggers=""/>
                </SidebarNavDropdown>
            </div>

            <!-- Camera Up Single Sensor-->
            <DeviceScreenShotPopup v-else :device="curObject" :dataChannel="curObject.getArchiveStreamDataChannel('0')">

                <!-- Single Sensor -->
                <div @click="showHideCamera($event, curObject, curObject.getFirstSourceToken(), curObject.getDataChannel(curObject.getFirstSourceToken(), globalQuality))"
                     :id="'preview-' + curObject.getDeviceId() + updateKey" class="d-flex align-items-center highlightEle rounded"
                     @dragstart="dragCamera($event, curObject, curObject.getFirstSourceToken(), curObject.getDataChannel(curObject.getFirstSourceToken(), globalQuality))" :draggable="isDraggable(curObject, '0')"
                     style="user-select: none" @dragover.prevent>

                    <b-tooltip placement="bottomleft" v-if="tooltipRender && target" ref="tooltip" :target="target" :title="tooltipTitle" triggers=""/>
                    <!-- If the device is being displayed -->
                    <span v-if="currentCameras.findIndex(_ => _.ssDevice.getDeviceId() === curObject.getDeviceId()) !== -1">
                        <fa-icon v-if="curObject.getDetectionStatus() === 0" :icon="['fas', 'video']"
                                 style="color: white;"/>
                        <VideoQuestionIcon v-else videoColor="#ffc107" questionColor="black" :fontSize="fontSize"/>
                    </span>

                    <!-- If the device is not being displayed -->
                    <span v-else>
                        <fa-icon v-if="curObject.getDetectionStatus() === 0" :icon="['fas', 'video']"
                                 style="color: #2a2a2a;"/>
                        <VideoQuestionIcon v-else videoColor="#2a2a2a" questionColor="#ffc107" :fontSize="fontSize"/>
                    </span>&nbsp;

                    <!-- Name for device -->
                    <OverflowAutoScroll v-if="curObject" :text="name" width="150px" :deviceId="curObject.getDeviceId()" :fontSize="fontSize"
                                        :style="'color: ' + (curObject.getDetectionStatus() === 0 ? 'inherit' : '#ffc107')"/>
                </div>
            </DeviceScreenShotPopup>
        </div>
    </div>

    <a v-else-if="isExternalLink" :href="url" :class="classList" v-bind="attributes">
        <fa-icon :icon="this.icon" style="color: #a0a0a0" class="nav-icon"/> {{ name }}
        <b-badge v-if="badge && badge.text" :variant="badge.variant">{{ badge.text }}</b-badge>
    </a>

    <router-link v-else :to="url" :class="classList" v-bind="attributes">
        <fa-icon :icon="this.icon" style="color: #a0a0a0" class="nav-icon" /> {{ name }}
        <b-badge v-if="badge && badge.text" :variant="badge.variant">{{ badge.text }}</b-badge>
    </router-link>
</template>

<script>
import Vue from 'vue'
import SidebarNavDropdown from "@/views/template_files/navbarJazz/components/Sidebar/SidebarNavDropdown";
import deviceStore from "@/store/deviceStore";
import OverflowAutoScroll from "@/components/OverflowAutoScroll";
import PubSub from "pubsub-js";
import GridPresets from "@/views/video_wall/GridPresets";
import DeviceScreenShotPopup from "@/components/deviceScreenShotPopup.vue";
import VideoQuestionIcon from "@/components/VideoQuestionIcon.vue";

export default {
    name: 'sidebar-nav-link',
    components: {
        SidebarNavDropdown,
        OverflowAutoScroll,
        DeviceScreenShotPopup,
        VideoQuestionIcon
    },
    props: {
        purpose: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        url: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        badge: {
            type: Object,
            default: () => {
            }
        },
        variant: {
            type: String,
            default: ''
        },
        classes: {
            type: [String, Array, Object],
            default: ''
        },
        attributes: {
            type: Object,
            default: () => {
                return Object.create(null)
            }
        },
        curObject: {
            type: Object
        },
        updateKey: {
            type: String,
            default: '0'
        },
        fontSize: {
            type: Number,
            default: 14
        }
    },
    data() {
        return {
            isTooltip: false,
            scroll: false,
            tooltipTitle: "",
            tooltipRender: true,
            target: '',
            reset: false,
            dragPubSub: null,
            cameraTogglePubSub: null,
            presetOptions: GridPresets
        }
    },
    created() {
        if (this.purpose === "videoWall") {
            this.cameraTogglePubSub = PubSub.subscribe('cameraToggle-' + this.curObject.getDeviceId(), (msg, obj) => {
                if (obj.open === true) {
                    this.reset = true;
                    this.curObject.running = true;
                    let payload = {
                        ssDevice: this.curObject,
                        sourceToken: obj.sourceToken || "0",
                        dataChannel: obj.dataChannel
                    };
                    deviceStore.dispatch('addActiveCamera', payload);
                    this.reset = false;
                } else {
                    this.showHideCamera(null, this.curObject, obj.sourceToken, obj.dataChannel);
                }
            })
        }
    },
    beforeDestroy() {
        PubSub.unsubscribe(this.dragPubSub);
        PubSub.unsubscribe(this.cameraTogglePubSub);
    },
    methods: {
        isDraggable(curObject, sourceToken) {
            return deviceStore.getters.getCurGridPreset !== 'Infinity Grid'
                && this.currentCameras.findIndex(_ => _.ssDevice.getDeviceId() === curObject.getDeviceId() && sourceToken === _.sourceToken) === -1;
        },
        rowClicked(item) {
            //ctrl click to make tooltip appear with id
            this.tooltipRender = false;
            this.tooltipTitle = item.getDeviceId();
            this.target = 'link-' + item.getDeviceId();
            //restart tooltip, so it can map to its new position
            Vue.nextTick().then(() => {
                this.tooltipRender = true;
                Vue.nextTick().then(() => {
                    this.$refs.tooltip.$emit('open');
                    setTimeout(function () {
                        //make the tooltip disapear automatically
                        this.$refs.tooltip.$emit('close');
                    }.bind(this), 4000);
                });
            });
        },
        getClassArray(classes) {
            return !classes ? [] : typeof classes === 'string' || classes instanceof String ? classes.split(' ') : Array.isArray(classes) ? classes : Object.keys(classes).filter(i => classes[i]);
        },
        showHideCamera(e, item, sourceToken, dataChannel) {
            let streamOptions = deviceStore.getters.getStreamOptions;
            let sToken = sourceToken || "0";
            if (!streamOptions.bool || (streamOptions.device.getDeviceId() === item.getDeviceId() &&
                streamOptions.sourceToken === sToken &&
                (streamOptions.device.isPanoramic() === true ? streamOptions.dataChannel === dataChannel : true))) {

                let index = this.currentCameras.findIndex(_ => _.sourceToken === sourceToken &&
                    _.ssDevice.getDeviceId() === item.getDeviceId() &&
                    (_.ssDevice.isPanoramic() === true ? _.dataChannel === dataChannel : true));
                if (e && e.ctrlKey) {
                    this.rowClicked(item);
                    return;
                }
                try {
                    this.reset = true;
                    this.curObject.running = true;
                    let payload = {
                        ssDevice: item,
                        sourceToken: sToken,
                        dataChannel: dataChannel,
                        deviceId: item && typeof item === 'object' ? item.getDeviceId() : null
                    };
                    if (index === -1) {
                        //add device
                        if (deviceStore.getters.getCurGridPreset === 'Infinity Grid'
                            || this.presetOptions[deviceStore.getters.getCurGridPreset].length > this.currentCameras.length) {
                            deviceStore.dispatch('addActiveCamera', payload);
                            if (deviceStore.getters.getCurGridPreset !== 'Infinity Grid' && e) {
                                PubSub.publish('addActiveCamera', payload);
                            }
                        }
                    } else {
                        //remove device
                        deviceStore.dispatch('removeActiveCamera', payload);
                    }
                    this.reset = false;
                } catch (e) {
                    this.$mToast({
                        title: 'Failed to open stream, could be offline',
                        style: 'error'
                    });
                }
            }
        },
        dragCamera(event, camera, sourceToken, dataChannel) {
            if (deviceStore.getters.getCurGridPreset !== 'Infinity Grid' && this.isDraggable(camera, sourceToken)) {
                dataChannel = dataChannel ? dataChannel : camera.getFirstDataChannel();
                sourceToken = sourceToken ? sourceToken : 0;
                PubSub.unsubscribe(this.dragPubSub);
                this.dragPubSub = PubSub.subscribe('cameraDrop-' + dataChannel, (msg, data) => {
                    if (data) {
                        this.showHideCamera(null, data.ssDevice, data.sourceToken, data.dataChannel);
                    }
                    this.showHideCamera(null, camera, sourceToken, dataChannel);
                    PubSub.unsubscribe(this.dragPubSub);
                })
                event.dataTransfer.setData('text', JSON.stringify({dataChannel: dataChannel, isStream: true}));
            }
        },
    },
    computed: {
        classList() {
            return [
                'nav-link',
                this.linkVariant,
                ...this.disabledClasses,
                ...this.attrClasses,
                ...this.itemClasses
            ];
        },
        classIcon() {
            return [
                'nav-icon',
                this.icon
            ];
        },
        linkVariant() {
            return this.variant ? `nav-link-${this.variant}` : '';
        },
        itemClasses() {
            return this.getClassArray(this.classes);
        },
        attrClasses() {
            return this.getClassArray(this.attributes.class);
        },
        disabledClasses() {
            return this.isDisabled ? 'disabled'.split(' ') : [];
        },
        isDisabled() {
            return Boolean(this.attributes.disabled);
        },
        isExternalLink() {
            return Boolean(this.url.substring(0, 4) === 'http');
        },
        currentCameras() {
            return deviceStore.getters.getCurrentCameras;
        },
        globalQuality() {
            return deviceStore.getters.getQuality;
        }
    }
}
</script>

<style scoped lang="css">
.highlightEle:hover {
    background-color: #595959;
}
</style>
