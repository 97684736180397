<template>
    <div>
        <div @mouseenter="mouseEnter" @mouseleave="mouseLeave" :id="`overflowElement_${dataChannel}`">
            <slot/>
        </div>
        <img v-if="imageReady" :id="`canvas_image_preview${dataChannel}`" alt="image preview"
             :style="`position: fixed; display: block; z-index: 100; top: ${elementPosition + elementHeight}px;`"
             :src="image" width="320" height="180">

    </div>
</template>

<script>
import PubSub from "pubsub-js";
export default {
    name: "deviceScreenShotPopup",
    props: {
        dataChannel: {
            type: String
        },
        device: {
            type: Object
        },
        sourceToken: {
            type: String,
            default: '0'
        }
    },
    data() {
        return {
            showPreviewTimeout: null,
            getPreviewTimeout: null,
            gettingImage: false,
            image: null,
            imageReady: false,
            elementPosition: 0,
            elementHeight: 0,
            mouseOver: false
        }
    },
    created() {
        PubSub.subscribe('close_image_preview', (msg, data) => {
            if (this.imageReady === true && data !== this.dataChannel) {
                this.imageReady = false;
            }
        })
    },
    beforeDestroy() {
        clearTimeout(this.getPreviewTimeout);
        clearTimeout(this.showPreviewTimeout);
        this.image = null;
        this.gettingImage = false;
        this.imageReady = false;
        this.mouseOver = false;
    },
    methods: {
        async mouseEnter(e) {
            this.mouseOver = true;
            this.getPreviewTimeout = setTimeout(async () => {
                const element = e.target.getBoundingClientRect();
                this.elementPosition = element.top;
                this.elementHeight = element.height;
                if (!this.device.checkForArchive(this.sourceId)) {
                    await this.device.createArchive(this.device, this.sourceToken, this.dataChannel);
                    await this.device.connectToArchive(this.sourceId);
                }
                if (this.gettingImage === false) {
                    this.gettingImage = true;
                    let dayCode = Math.floor(Date.now()/86400000);
                    let hourCode = new Date(Date.now()).getUTCHours();
                    this.device.getArchiveSegments(this.sourceId, dayCode, hourCode, segments => {
                        if (segments !== undefined && segments.length > 0) {
                            //grab the last segment since it is closest to Date.now()
                            let segment = segments[segments.length-1];
                            this.device.getKeyFrameImages(this.sourceId, [{dayCode: dayCode, hourCode: hourCode, segment: segment}], image => {
                                if (image !== undefined) {
                                    this.image = 'data:image/jpg;base64,' + image.image;
                                    this.gettingImage = false;
                                }
                            });
                        }
                    });
                }
            }, 250);
            this.displayImagePreview();
        },
        mouseLeave() {
            this.mouseOver = false;
            this.imageReady = false;
            clearTimeout(this.getPreviewTimeout);
            clearTimeout(this.showPreviewTimeout);
            this.image = null;
            this.gettingImage = false;
        },
        displayImagePreview() {
            this.showPreviewTimeout = setTimeout(async () => {
                if (this.mouseOver === true) {
                    if (this.image !== null) {
                        //this closes any other existing image previews before displaying the one you are hovering over
                        PubSub.publish('close_image_preview', this.dataChannel);
                        this.imageReady = true;
                    } else {
                        this.displayImagePreview();
                    }
                }
            }, 1000);
        }
    },
    computed: {
        sourceId() {
            return this.device.getDeviceId() + this.sourceToken + this.dataChannel;
        }
    }
}
</script>

<style scoped>

</style>
