<template>
    <div>

        <!-- Main -->
        <div v-if="exports.length > 0" style="max-height: 500px; overflow-y: auto">
            <div v-for="_export in exports">
                <div style="padding: 4px 5px;" :title="_export.exportName" :key="_export.jobId + '_export'">
                    <div class="rounded w-100" style="padding: 5px 0; background-color: #2e2e2e">
                        <div class="d-flex justify-content-between">
                            <div style="padding: 0 5px; font-size: 12px; word-break: break-word;">Name: {{_export.exportName}}</div>

                            <!-- indicates a deletion is in progress for the selected export -->
                            <div v-if="isDeleting(_export.jobId) === true" style="margin-top: -3px;">
                                <div>
                                    <fa-icon :icon="['fas', 'spinner']" spin fixed-width
                                             style="font-size: 14px; margin-right: 8px;"/>
                                </div>
                            </div>

                            <!-- confirm delete export elements -->
                            <div v-else-if="selectedDeleteExport === _export.jobId" style="margin-top: -3px; padding-right: 3px; cursor:pointer;" class="d-flex">
                                <div>
                                    <fa-icon :icon="['fas', 'remove']" fixed-width
                                       @click.stop="selectedDeleteExport = null"
                                       style="font-size: 12px;"
                                       title="Cancel Deletion"/>
                                </div>
                                <div>
                                    <fa-icon :icon="['fas', 'check']" fixed-width
                                       @click.stop="deleteExport(_export)"
                                       style="font-size: 12px;"
                                       title="Confirm Deletion"/>
                                </div>
                            </div>

                            <!-- download/delete export elements -->
                            <div v-else-if="_export.downloadReady" style="margin-top: -3px; padding-right: 3px; cursor:pointer;" class="d-flex">
                                <div v-if="canDeleteExports">
                                    <fa-icon :icon="['fas', 'trash']" fixed-width
                                       @click.stop="selectedDeleteExport = _export.jobId"
                                       style="font-size: 12px;"
                                       title="Delete Export"/>
                                </div>
                                <div>
                                    <fa-icon :icon="['fas', 'download']" fixed-width
                                       @click.stop="downloadFile(_export.downloadString, _export.jobId)"
                                       style="font-size: 12px;"
                                       title="Download Export"/>
                                </div>
                            </div>
                        </div>

                        <!-- Download progress -->
                        <div style="margin: 0 4px; position: relative" v-if="!_export.downloadReady">
                            <div style="position: absolute; color: white"
                                 class="d-flex justify-content-center align-items-center w-100 h-100">
                                <div v-if="_export.jobStage === 'Queued'">Queued</div>
                                <div v-else-if="_export.percentComplete !== 100">
                                    {{((_export.percentComplete / 100) * 100).toFixed(0)}}%
                                </div>
                                <div v-else>Finalizing...</div>
                            </div>
                            <b-progress style="border-radius: 50px" :max="100" animated>
                                <b-progress-bar :value="_export.percentComplete"/>
                            </b-progress>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="col-12">
            <b>No exports currently in system.</b>
        </div>
    </div>
</template>

<script>
import authStore from "@/store/authStore";
import Vue from 'vue'

export default {
    name: "SmartSuiteExports",
    props: {
        device: {
            type: Object
        },
        dataChannel: {
            type: String,
            default: undefined
        },
        sourceToken: {
            type: String,
            default: '0'
        },
        archiveOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            exports: [],
            selectedDeleteExport: null,
            deletingExportsList: {}
        }
    },
    async created() {
        await this.device.createArchive(this.device, this.sourceToken, this.dataChannel);
        await this.device.connectToArchive(this.archiveId);
        this.device.getExports(this.archiveId, response => {
            this.exports = this.exports.concat(response);
        });
        this.exportListeners();
    },
    beforeDestroy() {
        if (this.archiveOnly === false) {
            this.device.closeArchiveConnection(this.archiveId);
        }
    },
    methods: {
        deleteExport(_export) {
            Vue.set(this.deletingExportsList, _export.jobId, true);
            this.device.deleteArchiveExport(this.archiveId, _export);
        },
        isDeleting(jobId) {
            return this.deletingExportsList[jobId] === true;
        },
        async downloadFile(uri, name) {
            uri = uri + '&access_token=' + await authStore.getters.getUserManager.getAccessToken();
            let moduleGrants = await authStore.dispatch('getModuleGrants');
            if (moduleGrants && moduleGrants.includes('archiveexport')) {
                let link = document.createElement("a");
                link.download = name;
                link.href = uri;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                this.$mToast({
                    title: 'You Do Not Have Permission to Download',
                    style: 'error'
                });
            }
        },
        exportListeners() {
            this.device.setExportUpdateListener(this.archiveId, this.exportUpdateListener);
            this.device.setExportUpdatesListener(this.archiveId, this.exportUpdatesListener);
        },
        exportUpdateListener(event) {
            if (event.storageServer === this.device.getStorageServer()){
                let index = this.exports.findIndex(_export => {
                    return _export.jobId === event.jobId;
                });
                if (index !== -1) {
                    Vue.set(this.exports, index, event);
                } else {
                    this.exports.push(event);
                }
            }
        },
        exportUpdatesListener(event) {
            this.exports = event;
        }
    },
    computed: {
        archiveId() {
            return this.device.getDeviceId() + this.sourceToken + this.dataChannel;
        },
        canDeleteExports() {
            return !!(authStore.getters.getUserRole === 'securityadmin' || authStore.getters.getUserRole === 'poweruser' || (authStore.getters.getFeatureGrants && authStore.getters.getFeatureGrants.includes('deleteexport')));
        }
    }
}
</script>

<style scoped>
</style>