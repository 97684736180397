<template>
    <div style="background-color: black" ref="timeline">
        <div class="boxHover" :id="'bookmark'+sourceId" v-if="archiveOnly === false" style="display:none;">
            <div><b>{{ bookmarkInfoBoxName + ': ' + bookmarkInfoBoxText }}</b></div>
            <div style="color: #aaaaaa"><b>{{ bookmarkInfoBoxTime }}</b></div>
        </div>
        <div class="boxHover" :id="'event'+sourceId" v-if="archiveOnly === false" style="display:none;">
            <div><b>Type: {{ eventTypeText }} Event</b></div>
            <div style="color: #aaaaaa"><span style="color: white">Start Time: </span><b>{{ eventStartTime }}</b></div>
            <div style="color: #aaaaaa"><span style="color: white">End Time: </span><b>{{ eventEndTime }}</b></div>
        </div>
        <div v-if="(overTimeline === true || overFilmStrip === true) && isMobile === false"
             class="d-flex justify-content-center" ref="timelineTimeStamp"
             :style="'left: ' + overTimelineStyle + 'px; position: absolute; font-size: 22px; z-index: 2; background-color: #0d0d0d'">
            {{ overTimelineDisplay }}
        </div>
        <div style="display: flex; justify-content: center;" :id="'timeline_button_row'+sourceId">
            <!--            <span v-if="overTimeline === true && isMobile ===  false" :style="'left: ' + (overTimelineStyle) + 'px'"
                            style="position: absolute; font-size: 18px; z-index: 2; background-color: #0d0d0d; justify-self: start">-->
            <b-button title="Screen Shot"
                      variant="outline-light"
                      style="color: white; border-color: black;"
                      @click="shoot()">
                <fa-icon :icon="['fas', 'camera']"/>
            </b-button>
            <b-button title="Play Live"
                      @click="goLive()"
                      v-if="archiveOnly === false"
                      :disabled="liveOnFlag === true || selectingTimeRange === true"
                      variant="outline-light"
                      style="color: red; border-color: black;">
                <fa-icon :icon="['fas', 'circle']"/>
            </b-button>
            <!--<b-button title="Rewind"
                      @click="rewind()"
                      :disabled="playingLiveVideo === true || selectingTimeRange === true"
                      variant="outline-light"
                      style="border-color: black;"
                      :style="rewindBool === true ? 'background-color: white; color: black;' : 'color: white;'">
                 <fa-icon :icon="['fas', 'backward']"/>
            </b-button>-->
            <b-button title="Jump Back 5 Seconds" v-if="hasArchiveStream === true"
                      @click="jumpBack()"
                      :disabled="liveOnFlag === true || selectingTimeRange === true || storageLocation === 1"
                      variant="outline-light"
                      style="border-color: black;">
                <fa-icon :icon="['fas', 'clock-rotate-left']"/>
            </b-button>

            <b-button v-if="hasArchiveStream === true && device.isPaused(sourceId) === false && draggingRedBar === false" title="Pause Video"
                      @click="pauseVideo()"
                      :disabled="playingLiveVideo === true || selectingTimeRange === true || storageLocation === 1"
                      variant="outline-light"
                      style="color: white; border-color: black; padding: 6px 10px;">
                <fa-icon :icon="['fas', 'fa-pause']" fixed-width/>
            </b-button>
            <b-button v-else-if="hasArchiveStream === true && (device.isPaused(sourceId) === true || draggingRedBar === true)" title="Play Video"
                      @click="playVideo()"
                      :disabled="playingLiveVideo === true || selectingTimeRange === true || storageLocation === 1"
                      variant="outline-light"
                      style="color: white; border-color: black; padding: 6px 10px;">
                <fa-icon :icon="['fas', 'fa-play']" fixed-width/>
            </b-button>
            <b-dropdown no-caret :disabled="playingLiveVideo === true || selectingTimeRange === true || storageLocation === 1"
                        toggle-class="outline-black" v-if="hasArchiveStream === true"
                        title="Playback Speed"
                        variant="outline-light">
                <span slot="button-content">
                        <fa-icon :icon="['fas', 'fast-forward']"
                                 :style="playingLiveVideo === true || selectingTimeRange === true ? 'cursor: default' : 'cursor: pointer'"/>
                </span>
                <b-dropdown-item @click="fastForward(3)">3x</b-dropdown-item>
                <b-dropdown-item @click="fastForward(2)">2x</b-dropdown-item>
                <b-dropdown-item @click="fastForward(1)">1x</b-dropdown-item>
                <b-dropdown-item @click="fastForward(.5)">0.5x</b-dropdown-item>
                <b-dropdown-item @click="fastForward(.25)">0.25x</b-dropdown-item>
            </b-dropdown>

            <b-button title="Select Playback/Export Time Range"
                      @click="selectingTimeRange = !selectingTimeRange; selectTimeRange()"
                      variant="outline-light"
                      style="color: lightskyblue; border-color: black;"
                      :style="selectingTimeRange === true ? 'background-color: white;' : ''"
                      v-if="isMobile === false && hasArchiveStream === true">
                <fa-icon :icon="['fas', 'left-right']"/>
            </b-button>
            <b-button title="Chase" v-if="hasArchiveStream === true"
                      @click="chaseTimeline()"
                      :disabled="timelineMoving === true || playingLiveVideo === true"
                      variant="outline-light"
                      style="border-color: black">
                <fa-icon :icon="['fas', 'car']"/>
            </b-button>
            <div v-if="hasArchiveStream === true" class="datePickerTheme">
                <b-form-datepicker v-model="jumpToDay"
                                   title="Jump to Date"
                                   button-variant="dark"
                                   :min="minDateSelection"
                                   :max="new Date()"
                                   @input="jumpToDate()"
                                   button-only/>
            </div>
            <b-button title="Generate Image Previews"
                      @click="toggleFilmStrip"
                      v-if="hasArchiveStream === true && storageLocation === 0"
                      variant="outline-light"
                      style="border-color: black">
                <fa-icon :icon="['fas', 'film']"/>
            </b-button>
            <b-button title="Zoom In"
                      @click="zoomInOut(-1)"
                      :disabled="stepPosition === 0"
                      variant="outline-light"
                      class="icon-magnifier-add"
                      style="border-color: black"
                      v-if="isMobile === true"/>
            <b-button title="Zoom Out"
                      @click="zoomInOut(1)"
                      :disabled="stepPosition === 10"
                      variant="outline-light"
                      class="icon-magnifier-remove"
                      style="border-color: black"
                      v-if="isMobile === true"/>
        </div>
        <canvas :id="'filmstripBlowupImage'+sourceId" :height="blowupHeight" :width="blowupWidth"
                style="background-color: red; position: fixed; display: none;"/>
        <div v-show="showFilmstrip === true && storageLocation === 0"
             :style="'height: ' + canvasHeight + 'px; width: ' + canvasWidth + 'px;'" style="background-color: black;">
            <canvas :height="canvasHeight"
                    :width="canvasWidth"
                    style="cursor: pointer"
                    @click="filmStripPlayback"
                    @mousemove="filmStripMouseMoved"
                    @mouseenter="findImageIndex"
                    @mouseleave="clearImageIndex"
                    :id="'canvas_filmstrip_' + sourceId"/>

        </div>
        <canvas @wheel="mouseScrollWheel"
                @mousedown="clickDown"
                @mouseup="clickUp"
                @mousemove="mouseMoved"
                @mouseenter="enterTimeline"
                @mouseleave="leaveTimeline"
                :height="canvasHeight"
                :width="canvasWidth"
                :id="'canvas_' + sourceId"
                style="background-color: #444444; touch-action: none;"/>
    </div>
</template>

<script>
import Vue from "vue";
import PubSub from 'pubsub-js';
import datePicker from "vue-bootstrap-datetimepicker";
import moment from "moment";
import eleQueries from "css-element-queries";
import {v4 as uuidv4} from 'uuid';
import {debounce} from 'lodash';
const REFRESH_INTERVAL = 100;
const External = 0;
const SdCard = 1;
export default {
    name: "SmartSuiteTimeline",
    props: {
        device: {
            type: Object,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
        sourceToken: {
            type: String,
            default: '0'
        },
        dataChannel: {
            type: String,
            default: undefined
        },
        archiveOnly: {
            type: Boolean,
            default: false
        }
    },
    components: {
        datePicker
    },
    data: () => {
        return {
            jumpToDay: '',
            canvasHeight: null,
            canvasWidth: null,
            //overTimeline is used to know when to display the actual time indicator above the timeline itself
            overTimeline: false,
            overFilmStrip: false,
            overTimelineDisplay: null,
            overTimelineStyle: null,
            timelineRollOver: null,
            referenceTime: null,
            clickDownX: 0,
            fingerDown1X: null,
            mouseTap: false,
            currentMousePosX: 0,
            currentMousePosY: 0,
            stepPosition: 2,
            playingLiveVideo: true,
            draggingRedBar: false,
            redBarLive: false,
            liveOnFlag: false,
            redBarTime: null,
            playbackTime: null,
            currentlyScrolling: false,
            currentlySliding: false,
            currentlyScaling: false,
            mouseOver: false,
            globalScaleFactor: 75,
            barStepArray: [
                {
                    ticksPerBig: 5,
                    interval: "sec",
                    millisecondPerTick: 1000,
                    millisecondPerBigTick: 5000,
                    scaleFactor: 60
                },
                {
                    ticksPerBig: 5,
                    interval: "sec",
                    millisecondPerTick: 2000,
                    millisecondPerBigTick: 10000,
                    scaleFactor: 60
                },
                {
                    ticksPerBig: 6,
                    interval: "sec",
                    millisecondPerTick: 5000,
                    millisecondPerBigTick: 30000,
                    scaleFactor: 70
                },
                {
                    ticksPerBig: 6,
                    interval: "min",
                    millisecondPerTick: 10000,
                    millisecondPerBigTick: 60000,
                    scaleFactor: 70
                },
                {
                    ticksPerBig: 4,
                    interval: "min",
                    millisecondPerTick: 30000,
                    millisecondPerBigTick: 120000,
                    scaleFactor: 55
                },
                {
                    ticksPerBig: 5,
                    interval: "min",
                    millisecondPerTick: 60000,
                    millisecondPerBigTick: 300000,
                    scaleFactor: 60
                },
                {
                    ticksPerBig: 5,
                    interval: "min",
                    millisecondPerTick: 120000,
                    millisecondPerBigTick: 600000,
                    scaleFactor: 60
                },
                {
                    ticksPerBig: 6,
                    interval: "min",
                    millisecondPerTick: 300000,
                    millisecondPerBigTick: 1800000,
                    scaleFactor: 70
                },
                {
                    ticksPerBig: 6,
                    interval: "hr",
                    millisecondPerTick: 600000,
                    millisecondPerBigTick: 3600000,
                    scaleFactor: 70
                },
                {
                    ticksPerBig: 4,
                    interval: "hr",
                    millisecondPerTick: 1800000,
                    millisecondPerBigTick: 7200000,
                    scaleFactor: 55
                },
                {
                    ticksPerBig: 6,
                    interval: "hr",
                    millisecondPerTick: 3600000,
                    millisecondPerBigTick: 21600000,
                    scaleFactor: 70
                }
            ],
            referenceTimeLocation: 0,       //location on the canvas where the starting time is relevant to
            selectingTimeRange: false,
            timeRangeStartTime: null,
            timeRangeEndTime: null,
            timeRangeDragTabs: {
                width: 14,
                height: 20
            },
            timelineMoving: false,
            isPlayingBack: false,
            currentTime: null,
            retention: 999999999999999999,
            archiveStreaming: false,
            frameBufferEndTime: null,
            frameBufferStartTime: null,
            pubsubs: [],
            queuedSegments: {},
            archiveDays: null,
            archiveHours: {},
            retentionStart: 0,
            retentionEnd: 99999999999999,
            missingFootageTimes: [],
            availableFootageTimes: [],
            fetchingArchiveTimes: false,
            fastForwardValue: 1,
            rewindBool: false,
            timeoutGetSdCardHours: null,
            timeoutJumpBack: null,
            timeoutFilmStrip: null,
            resizeTimeout: null,
            eventsArray: [],
            bookmarksArray: [],
            hoverEventId: null,
            storageLocation: 0, //0-archive 1-sd card
            sdCardRecordings: [],
            frameWidth: 0,
            frameHeight: 0,
            selectedImageIndex: null,
            filmstripImages: [],
            filmstripRequests: [],
            filmstripDone: false,
            showFilmstrip: false,
            cyclingFilmstrip: false,
            movingFilmstrip: false,
            currentFilmstripId: null,
            createNewFilmstrip: true,
            switchingToLivestream: false,
            bookmarkInfoBoxText: '',
            bookmarkInfoBoxName: '',
            bookmarkInfoBoxTime: '',
            hasArchiveStream: false,
            eventTypeText: '',
            eventStartTime: null,
            eventEndTime: null,
            intervalFrameBuffer: null,
            intervalAvailableTimes: null,
            intervalGetAvailableArchiveTime: null,
            intervalCurrentTime: null,
            intervalTimeLine: null,
            intervalRedBar: null,
            intervalMouseDrag: null,
            intervalFindImageIndex: null,
            intervalOverTimeline: null,
            intervalOverFilmstrip: null,
            blowupWidth: 640,
            blowupHeight: 360,
            blowupImage: null,
            mouseDown: false
        }
    },

    async created() {
        new eleQueries.ResizeSensor(document.getElementById('stream_settings_container_' + this.sourceId), () => {
            clearTimeout(this.resizeTimeout)
            this.resizeTimeout = setTimeout(() => {
                this.setup(true);
            }, 500);
        });
        this.referenceTime = this.redBarTime = Date.now();
        if (this.archiveOnly) {
            this.hasArchiveStream = true;
        } else {
            this.hasArchiveStream = await this.device.getArchiveStream(this.sourceToken) !== undefined;
        }
        await this.device.createArchive(this.device, this.sourceToken, this.dataChannel);
        this.intervalCurrentTime = setInterval(() => {
            this.currentTime = Date.now();
        }, REFRESH_INTERVAL);
        //this interval periodically grabs the time archive footage that is available and displays it on the timeline
        this.intervalAvailableTimes = setInterval(async () => {
            if (this.archiveOnly) {
                this.hasArchiveStream = true;
            } else {
                this.hasArchiveStream = await this.device.getArchiveStream(this.sourceToken) !== undefined;
            }
            this.getAvailableArchiveTime();
        }, 20000);
        this.pubsubs.push(PubSub.subscribe('timeRange', async (msg, data) => {
            if ((Math.floor(this.timeRangeStartTime / 1000) * 1000) !== data.startTime || (Math.floor(this.timeRangeEndTime / 1000) * 1000) !== data.endTime || !this.isPlayingBack) {
                this.timeRangeStartTime = data.startTime;
                this.timeRangeEndTime = data.endTime;
                this.redBarTime = data.startTime;
                this.liveOnFlag = false;
                this.isPlayingBack = true;
                await this.goPlayback(data.startTime, data.endTime, this.canvasWidth * .1, true);
                this.getAvailableArchiveTime();
            }
        }));
        this.pubsubs.push(PubSub.subscribe('timeRangeSpecific', async (msg, data) => {
            this.queuedSegments = {};
            this.queuedSegments[0] = data.times;
            this.timeRangeStartTime = this.queuedSegments[0][0].segment;
            this.timeRangeEndTime = this.queuedSegments[0][data.times.length - 1].endTime;
            this.redBarTime = data.currentTime;
            this.liveOnFlag = false;
            this.isPlayingBack = true;
            await this.setPlaybackVars(this.queuedSegments[0][data.index].segment, this.canvasWidth * .1, true);
            let request = await this.device.streamArchiveRequest(this.sourceId, this.queuedSegments[0]);
            request = Object.assign({requestId: request}, {
                startTime: this.queuedSegments[0][data.index].segment,
                endTime: this.queuedSegments[0][data.times.length - 1].endTime,
                segment: this.queuedSegments[0][0].segment
            });
            this.device.startPlayback(this.sourceId, request, false);
            this.frameBufferStartTime = this.queuedSegments[0][0].segment;
            this.archiveStreaming = true;
        }));
        this.pubsubs.push(PubSub.subscribe('startPlayback' + this.sourceId, async (msg, data) => {
            this.referenceTime = this.redBarTime = data;
            this.timeRangeStartTime = this.timeRangeEndTime = null;
            await this.getAvailableArchiveTime();
            await this.goPlayback(data, null, this.canvasWidth * .75, false);
        }));
        this.pubsubs.push(PubSub.subscribe('archiveStreamingDone', () => {
            this.archiveStreaming = false;
        }));
        this.pubsubs.push(PubSub.subscribe('request_next_archive_segment', async (msg, data) => {
            if (this.queuedSegments[data.index] !== undefined) {
                //this plays the next segment for a timerange
                let request = await this.device.streamArchiveRequest(this.sourceId, this.queuedSegments[data.index]);
                request = Object.assign({requestId: request}, {
                    startTime: this.queuedSegments[data.index][0].segment,
                    endTime: this.queuedSegments[data.index][0].endTime,
                    segment: this.queuedSegments[data.index][0].segment
                });
                if (data.previous === true) {
                    this.device.previousPlaybackSegment(this.sourceId, request);
                } else {
                    this.device.nextPlaybackSegment(this.sourceId, request);
                }
            } else if (data.override === true) {
                await this.goPlayback(data.startTime, data.startTime + 25000, this.redBarLocation, false, data.override);
            } else if (data.isContinuous === true) {
                //if you just clicked on the timeline this finds and plays the next segment
                this.startPlayback(data.startTime, false, data.previous);
            } else if (this.queuedSegments[data.index] === undefined) {
                this.device.clearBuffers(this.sourceId);
            }
        }));
        this.pubsubs.push(PubSub.subscribe('export_request_archive_segments', async (msg, data) => {
            let record = null
            if (this.storageLocation === SdCard) {
                record = await this.getSdRecord(data.start);
                PubSub.publish('export_send_archive_segments', {
                    storageLocation: this.storageLocation,
                    segments: null,
                    record: record
                });
            } else if (this.storageLocation === External) {
                this.device.findArchivedSegments(this.sourceId, data.start, data.end, segments => {
                    PubSub.publish('export_send_archive_segments', {
                        storageLocation: this.storageLocation,
                        segments: segments,
                        record: record
                    });
                });
            }
        }));
        this.pubsubs.push(PubSub.subscribe('setBufferStartTime', (msg, data) => {
            this.frameBufferStartTime = data;
        }));
        this.pubsubs.push(PubSub.subscribe('sendEventList' + this.sourceId, (msg, data) => {
            this.eventsArray = data;
        }))
        this.pubsubs.push(PubSub.subscribe('regetEventsList' + this.sourceId, () => {
            this.getEventsList();
        }));
        this.pubsubs.push(PubSub.subscribe('sendBookmarks' + this.sourceId, (msg, data) => {
            this.bookmarksArray = data;
        }))
        this.pubsubs.push(PubSub.subscribe('storageChanged' + this.sourceId, async (msg, data) => {
            this.storageLocation = data;
            this.availableFootageTimes = [];
            this.missingFootageTimes = [];
            clearInterval(this.intervalAvailableTimes);
            if (data === SdCard) {
                await this.device.createSdCard(this.device, this.sourceToken, this.dataChannel);
                await this.device.connectToSdCard(this.sourceId);
                this.device.setSdCardRecordingListener(this.sourceId, (records) => {
                    this.sdCardRecordings = records.recordingList.recording;
                    this.sdCardRecordings.sort((a, b) => {
                        if (a.starttime < b.starttime)
                            return 1;
                        if (a.starttime > b.starttime)
                            return -1;
                        return 0;
                    });
                    this.getAvailableSdCardTime(records.recordingList.recording);
                })
                this.intervalAvailableTimes = setInterval(() => {
                    this.device.getSdCardRecordings(this.sourceId);
                }, 20000);
                this.device.getSdCardRecordings(this.sourceId);
            } else if (data === External) {
                clearTimeout(this.timeoutGetSdCardHours);
                this.intervalAvailableTimes = setInterval(() => {
                    this.startGetFilmStripTimout(0, this.startBarTime, true);
                    this.getAvailableArchiveTime();
                }, 20000);
                this.startGetFilmStripTimout(0, this.startBarTime, true);
                this.getAvailableArchiveTime();
            }
        }));
        this.pubsubs.push(PubSub.subscribe('livestreamPlaying' + this.sourceId, async () => {
            this.switchingToLivestream = false;
            await this.clearFilmStripImages();
            this.startGetFilmStripTimout(0, this.startBarTime, true);
        }));
        this.pubsubs.push(PubSub.subscribe('getCurrentTime' + this.sourceId, async () => {
            PubSub.publish('sendCurrentTime' + this.sourceId, this.redBarTime);
        }));
    },
    async mounted() {
        await this.setup();
        this.canvasElement.addEventListener('touchstart', this.fingerDown);
        this.canvasElement.addEventListener('touchmove', this.fingerMove);
        this.canvasElement.addEventListener('touchend', this.fingerUp);
    },
    beforeDestroy() {
        PubSub.publish('isPlayingBack' + this.sourceId, false);
        //make sure all the setIntervals are closed when the window is exited
        clearInterval(this.intervalTimeLine);
        clearInterval(this.intervalRedBar);
        clearInterval(this.intervalMouseDrag);
        clearInterval(this.intervalCurrentTime);
        clearInterval(this.intervalFrameBuffer);
        clearInterval(this.intervalAvailableTimes);
        clearInterval(this.intervalGetAvailableArchiveTime);
        clearInterval(this.intervalFindImageIndex);
        clearInterval(this.intervalOverTimeline);
        clearInterval(this.intervalOverFilmstrip);
        //close all pubsubs
        this.pubsubs.forEach(sub => {
            PubSub.unsubscribe(sub)
        });
        this.device.closeSdCardConnection(this.sourceId);
    },

    methods: {
        clearImageIndex() {
            this.selectedImageIndex = null;
            this.overFilmStrip = false;
            clearInterval(this.intervalFindImageIndex);
            let canvas = document.getElementById('filmstripBlowupImage' + this.sourceId);
            canvas.style.display = 'none';
        },
        async findImageIndex() {
            this.overFilmStrip = true;
            this.intervalFindImageIndex = setInterval(() => {
                if (this.filmstripImages.length > 0) {
                    let index = this.filmstripImages.findLastIndex(image => {
                        if (image) {
                            return image.imageStartTime < this.mouseTime;
                        }
                    });
                    let canvas = document.getElementById('filmstripBlowupImage' + this.sourceId);
                    if (index !== -1 && this.filmstripImages[index].image !== undefined && this.availableFootageTimes.length > 0 && this.availableFootageTimes[this.availableFootageTimes.length - 1].endTime > this.filmstripImages[index].imageStartTime) {
                        this.selectedImageIndex = index;
                        let parent = this.canvasFilmStripElement;
                        let buttonRow = document.getElementById('timeline_button_row' + this.sourceId);
                        let pageOffset = parent.getBoundingClientRect();
                        //handle position on page of image
                        if (pageOffset.left + this.convertTimeToLocation(this.mouseTime) + this.blowupWidth / 2 > pageOffset.left + this.convertTimeToLocation(this.endBarTime)) {
                            canvas.style.left = pageOffset.left + this.convertTimeToLocation(this.endBarTime) - this.blowupWidth + 'px';
                        } else if (pageOffset.left + this.convertTimeToLocation(this.mouseTime) - this.blowupWidth / 2 < pageOffset.left) {
                            canvas.style.left = pageOffset.left + 'px';
                        } else {
                            canvas.style.left = pageOffset.left + this.convertTimeToLocation(this.mouseTime) - this.blowupWidth / 2 + 'px';
                        }
                        //set image and display
                        this.blowupImage = this.filmstripImages[index].image;
                        let ctx = canvas.getContext("2d");
                        ctx.drawImage(this.blowupImage, 0, 0, this.blowupWidth, this.blowupHeight);
                        ctx.strokeStyle = 'black';
                        ctx.lineWidth = 3;
                        ctx.strokeRect(0, 0, this.blowupWidth, this.blowupHeight - 1);
                        canvas.style.display = 'block';
                        canvas.style.top = pageOffset.top - (this.blowupHeight + buttonRow.offsetHeight) + 'px';
                    } else {
                        canvas.style.display = 'none';
                        this.selectedImageIndex = null;
                    }
                }
            }, 50);
        },
        async filmStripPlayback(e) {
            if (this.filmstripDone === true) {
                let result = await this.filmstripImages.findLast(image => {
                    return image.imageStartTime < this.convertLocationToTime(e.offsetX);
                });
                if (result.image !== undefined) {
                    this.timeRangeStartTime = this.timeRangeEndTime = null;
                    await this.goPlayback(result.imageStartTime, result.imageStartTime + (25 * 1000), this.convertTimeToLocation(result.imageStartTime), false, false, true);
                }
            }
        },
        async toggleFilmStrip() {
            await this.clearFilmStripImages();
            this.showFilmstrip = !this.showFilmstrip;
            this.startGetFilmStripTimout(0, this.startBarTime, true);
        },
        shoot() {
            let title, tempElement;
            tempElement = document.createElement("a");
            tempElement.setAttribute('id', 'link');
            if (this.device.getMultiSensor() > 1) {
                title = this.device.getMultiSensorStreams().find(_ => _.sourceToken === this.sourceToken).friendlyName;
            } else {
                title = this.device.getDeviceName();
            }
            let canvas = document.getElementById('video_canvas_' + this.device.getDeviceId() + this.sourceToken + this.dataChannel);
            tempElement.href = canvas.toDataURL('image/png');
            tempElement.download = title + ' ' + new Date(this.redBarTime).toLocaleString() + '.png';
            tempElement.click();
            tempElement.remove();
        },
        jumpBack() {
            clearTimeout(this.timeoutJumpBack);
            this.timeoutJumpBack = setTimeout(() => {
                if (Object.keys(this.queuedSegments).length > 0) {
                    if (this.redBarTime - 5000 < this.frameBufferStartTime) {
                        this.device.setInstanceTime(this.sourceId, this.frameBufferStartTime, false);
                    } else {
                        this.device.setInstanceTime(this.sourceId, this.redBarTime - 5000, false);
                    }
                } else {
                    this.startPlayback(this.redBarTime - 7000);
                }
                if (this.fastForwardValue !== 1) {
                    this.fastForwardValue = 1;
                }
            }, 250);
        },
        rewind() {
            this.rewindBool = !this.rewindBool;
            if (this.rewindBool === true) {
                this.device.rewind(this.sourceId);
            } else {
                this.device.stopRewind(this.sourceId);
            }
            if (this.fastForwardValue !== 1) {
                this.fastForwardValue = 1;
            }
        },
        fastForward(value) {
            this.fastForwardValue = value;
            this.device.fastForward(this.sourceId, this.fastForwardValue);
            if (this.rewindBool === true) {
                this.rewindBool = false;
            }
        },
        enterTimeline() {
            this.overTimeline = true;
        },
        leaveTimeline() {
            this.overTimeline = false;
            this.mouseDown = false;
            this.movingFilmstrip = false;
        },
        pauseVideo() {
            this.timelineMoving = false;
            this.device.pause(this.sourceId);
            if (this.rewindBool === true) {
                this.rewindBool = false;
            }
            if (this.fastForwardValue !== 1) {
                this.fastForwardValue = 1;
            }
        },
        playVideo() {
            this.device.play(this.sourceId);
            this.timelineMoving = true;
        },
        chaseTimeline() {
            this.endTimelineScroll();
            this.endRedBarScroll();
            this.referenceTimeLocation = this.redBarLocation;
            if (this.referenceTimeLocation < this.canvasWidth * .1 || this.referenceTimeLocation > this.canvasWidth * .8) {
                this.referenceTimeLocation = this.canvasWidth * .8;
            }
            this.redBarLive = false;
            this.playingLiveVideo = false;
            this.intervalTimeLine = setInterval(() => {
                this.playPlayback();
            }, REFRESH_INTERVAL);
            this.timelineMoving = true;
            this.intervalRedBar = setInterval(() => {
                this.redPlayback();
            }, REFRESH_INTERVAL);
        },
        checkForRedBarScrolling() {
            if (this.currentMousePosX !== this.clickDownX) {
                this.draggingRedBar = true;
                this.mouseTap = false;
                //if you are not past retention
                if (this.mouseTime < this.futureTime && this.mouseTime > this.pastTime) {
                    //if you have made a playback range and are inside the buffer
                    if (this.timeRangeStartTime !== null && this.timeRangeEndTime !== null && this.insideTimeBuffer === true) {
                        this.redBarTime -= (this.clickDownX - this.currentMousePosX) * this.timePerPixel;
                        this.clickDownX = this.currentMousePosX;
                        this.device.setInstanceTime(this.sourceId, this.redBarTime, true);
                    } else if (this.timeRangeStartTime === null && this.timeRangeEndTime === null) {    //not a playback range
                        this.redBarTime -= (this.clickDownX - this.currentMousePosX) * this.timePerPixel;
                        this.clickDownX = this.currentMousePosX;
                        this.device.setInstanceTime(this.sourceId, this.redBarTime, true);
                    }
                }
            }
            this.refreshPage();
        },
        checkForTimeLineScrolling() {
            //this function checks for movement and only allows it if the actual time exists
            if (this.mouseDown === true) {
                if (this.mouseTap === true && this.clickDownX > this.currentMousePosX + 3 || this.clickDownX < this.currentMousePosX - 3) {
                    this.mouseTap = false;
                }
                if (this.currentMousePosX !== this.clickDownX) {
                    if (this.showFilmstrip === true && this.filmstripImages.length > 0) {
                        this.clearFilmStripImages();
                    }
                    this.referenceTimeLocation -= this.clickDownX - this.currentMousePosX;
                    this.clickDownX = this.currentMousePosX;
                }
            }
            this.refreshPage();
        },
        checkForTimeRangeScrolling() {
            if (this.mouseTime + 20 * 1000 < Date.now()) {
                this.timeRangeEndTime = this.mouseTime;
            }
            this.refreshPage();
        },
        cleanUp() {
            //this clears the canvas to be redrawn
            let c = this.canvasElement;
            let ctx = c.getContext("2d");
            ctx.clearRect(0, 0, c.width, c.height);
            ctx.beginPath();
            if (this.showFilmstrip === true) {
                let c2 = this.canvasFilmStripElement;
                let ctx2 = c2.getContext("2d");
                ctx2.clearRect(0, 0, c2.width, c2.height);
                ctx2.beginPath();
            }
        },
        clickDown(clickInfo) {
            clearInterval(this.intervalMouseDrag);
            if (this.selectingTimeRange === false) {
                this.endTimelineScroll();
                this.mouseTap = true;
                this.mouseDown = true;
                this.clickDownX = clickInfo.offsetX;
                //drag the redbar
                if (this.overRedBar === true) {
                    this.endRedBarScroll();
                    this.device.pause(this.sourceId);
                    this.intervalMouseDrag = setInterval(this.checkForRedBarScrolling, REFRESH_INTERVAL);
                } else if (this.overBeginDragTag || this.overEndDragTag) { //drag a timerange end tab
                    this.clearFrameBuffer();
                    this.selectingTimeRange = true;
                    if (this.overBeginDragTag) {
                        this.timeRangeStartTime = this.timeRangeEndTime;
                    }
                    this.isPlayingBack = false;
                    this.timeRangeEndTime = this.mouseTime
                    this.intervalMouseDrag = setInterval(this.checkForTimeRangeScrolling, REFRESH_INTERVAL);
                } else {    //click and drag the timeline
                    this.intervalMouseDrag = setInterval(this.checkForTimeLineScrolling, REFRESH_INTERVAL);
                    this.currentlyScrolling = true;
                }
            } else if (this.mouseTime < Date.now() && this.mouseTime > (this.currentTime - this.retention)) {
                this.clearFrameBuffer();
                this.timeRangeStartTime = this.timeRangeEndTime = this.mouseTime;
                this.intervalMouseDrag = setInterval(this.checkForTimeRangeScrolling, REFRESH_INTERVAL);
            }
        },
        async clickUp() {
            clearInterval(this.intervalMouseDrag);
            this.draggingRedBar = false;
            if (this.selectingTimeRange === false) {
                this.mouseDown = false;
                //if you are currently hovering over an event
                if (this.hoverEventId !== null && this.clickDownX === this.currentMousePosX) {
                    await this.clearFrameBuffer();
                    this.endRedBarScroll();
                    let hoveredEvent = this.eventsArray.find(event => {
                        return event.id === this.hoverEventId;
                    });
                    this.timeRangeStartTime = hoveredEvent.timeStamp;
                    this.timeRangeEndTime = hoveredEvent.endTime;
                    PubSub.publish("timeRangeSelection", {start: this.timeRangeStartTime, end: this.timeRangeEndTime});
                    this.liveOnFlag = false;
                    this.isPlayingBack = true;
                    await this.goPlayback(this.timeRangeStartTime, this.timeRangeEndTime, this.timeRangeStartTimeLocation, true);
                } else if (this.mouseTap === true && this.mouseTime + 20 * 1000 < Date.now() && this.mouseTime > (this.currentTime - this.retention)) {
                    //if you tap inside the timeline where we have footage available
                    let insideLiveZone = false, count = 0;
                    if (this.availableFootageTimes.length === 0) {
                        this.availableFootageTimes.push({startTime: 0, endTime: 0});
                    }
                    //check to see if you clicked where we have footage
                    this.availableFootageTimes.forEach(time => {
                        if (time.startTime < this.mouseTime && time.endTime > this.mouseTime) {
                            insideLiveZone = true;
                        }
                        count++;
                        if (count === this.availableFootageTimes.length) {
                            //if we have footage where you clicked
                            if (insideLiveZone === true) {
                                this.isPlayingBack = true;
                                this.endRedBarScroll();
                                //the timeout is needed so that the last redbar interval finishes and prevents a flicker of it
                                setTimeout(async () => {
                                    //If you click inside the blue range of playback
                                    if (this.insideTimeRange === true && this.mouseTime < this.frameBufferEndTime && this.mouseTime > this.frameBufferStartTime) {
                                        this.redBarTime = this.mouseTime;
                                        this.device.setInstanceTime(this.sourceId, this.redBarTime, false);
                                        this.intervalRedBar = setInterval(this.redPlayback, REFRESH_INTERVAL);
                                    } else {
                                        //else if you just click on the timeline it will play continuously from there
                                        await this.clearFrameBuffer();
                                        this.timeRangeStartTime = this.timeRangeEndTime = null;
                                        await this.goPlayback(Math.floor(this.mouseTime), null, Math.floor(this.currentMousePosX), false);
                                    }
                                }, REFRESH_INTERVAL);
                            }
                        }
                    });
                } else if (this.mouseTap === false && this.timeRangeStartTime !== null && this.timeRangeEndTime !== null) {
                    //when you release the redbar while dragging it inside a time range
                    this.device.setInstanceTime(this.sourceId, this.redBarTime, false);
                    await this.endRedBarScroll();
                    this.intervalRedBar = setInterval(this.redPlayback, REFRESH_INTERVAL);
                } else if (this.mouseTap === false && this.overRedBar === true && this.timeRangeStartTime === null && this.timeRangeEndTime === null) {
                    //if you are dragging the redbar outside a time range
                    this.isPlayingBack = true;
                    this.endRedBarScroll();
                    setTimeout(async () => {
                        await this.clearFrameBuffer();
                        this.timeRangeStartTime = this.timeRangeEndTime = null;
                        await this.goPlayback(Math.floor(this.mouseTime), null, Math.floor(this.currentMousePosX), false);
                    });
                }
                this.mouseTap = false;
                this.currentlyScrolling = false;
            } else {    //this else is for selecting a time range to playback
                await this.clearFrameBuffer();
                this.endRedBarScroll();
                //I need to drop the ms values for sd card since it doesn't account for ms
                if (this.storageLocation === SdCard) {
                    this.timeRangeStartTime = Math.floor(this.timeRangeStartTime / 1000) * 1000;
                    this.timeRangeEndTime = Math.floor(this.timeRangeEndTime / 1000) * 1000;
                }
                if (this.timeRangeEndTime < this.timeRangeStartTime) {
                    let flip = this.timeRangeStartTime;
                    this.timeRangeStartTime = this.timeRangeEndTime;
                    this.timeRangeEndTime = flip;
                }
                PubSub.publish("timeRangeSelection", {start: this.timeRangeStartTime, end: this.timeRangeEndTime});
                this.liveOnFlag = false;
                this.isPlayingBack = true;
                await this.goPlayback(this.timeRangeStartTime, this.timeRangeEndTime, this.timeRangeStartTimeLocation, true);
            }
            this.refreshPage();
            if (this.storageLocation === External) {
                this.startGetFilmStripTimout(0, this.startBarTime, true);
                this.getAvailableArchiveTime();
            } else if (this.storageLocation === SdCard) {
                this.device.getSdCardRecordings(this.sourceId);
            }
        },
        giveDate(time) {
            return new Date(parseInt(time)).toLocaleString();
        },
        convertTime(time) {
            let date = new Date(time);
            if (this.stepPosition === 10 || this.stepPosition === 9 || this.stepPosition === 8) {
                return moment(date).format('h A');
            } else if (this.stepPosition === 7 || this.stepPosition === 6 || this.stepPosition === 5 || this.stepPosition === 4 || this.stepPosition === 3) {
                return moment(date).format('h:mm A');
            } else {
                return moment(date).format('h:mm:ss A');
            }
        },
        clearFilmStripImages() {
            if (this.filmstripImages.length > 0) {
                this.filmstripImages = [];
            }
            if (this.filmstripRequests.length > 0) {
                this.filmstripRequests = [];
            }
        },
        async cycleFilmStripImages() {
            if (this.showFilmstrip === true && this.filmstripDone === true) {
                this.filmstripImages.splice(0, 3);
                this.filmstripRequests.splice(0, 3);
                let last = this.filmstripImages.slice(-1);
                this.getFilmStripImages(this.filmstripImages.length, last[0].imageStartTime + (this.frameWidth * this.timePerPixel), true, true);
            }
        },
        startGetFilmStripTimout(index, startTime, override) {
            if (this.hasArchiveStream === false) {return;}
            if (this.showFilmstrip === true) {
                this.movingFilmstrip = true;
                this.createNewFilmstrip = true;
                clearTimeout(this.timeoutFilmStrip);
                this.timeoutFilmStrip = setTimeout(() => {
                    this.movingFilmstrip = false;
                    this.getFilmStripImages(index, startTime, override);
                }, 1500);
            }
        },
        getFilmStripImages(index, startTime, override, addImage, requestArray) {
            if (index <= this.totalImages && (this.timelineMoving === true || override === true) && this.showFilmstrip === true && this.hasArchiveStream === true) {
                if (addImage !== true) {
                    this.currentFilmstripId = uuidv4();
                }
                let dayCode = Math.floor(new Date(startTime).valueOf() / 86400000);
                let hourCode = new Date(startTime).getUTCHours();
                this.device.getArchiveSegments(this.sourceId, dayCode, hourCode, async segments => {
                    //grab the segment that the startTime would be inside of
                    let keyFrameSegment;
                    if (segments !== undefined) {
                        keyFrameSegment = segments.findLast(segment => {
                            return parseInt(segment) < startTime;
                        });
                    }
                    let imageStartTime;
                    if (index === 0 && keyFrameSegment !== undefined) {
                        imageStartTime = parseInt(keyFrameSegment);
                    } else {
                        imageStartTime = startTime;
                    }
                    let nextStartTime = this.convertLocationToTime(this.convertTimeToLocation(parseInt(imageStartTime)) + this.frameWidth);
                    if (index === 0) {
                        this.clearFilmStripImages();
                        for (let i = 1; i < this.totalImages; i++) {
                            this.getFilmStripImages(i, nextStartTime + ((i - 1) * (this.frameWidth) * this.timePerPixel), true);
                        }
                    }
                    //used as a placeholder if the image isn't there yet, or it fails to get an image
                    if (this.cyclingFilmstrip === true) {
                        this.cyclingFilmstrip = false;
                    }
                    if (this.filmstripImages[index] === undefined) {
                        this.filmstripImages[index] = {};
                    }
                    //get image
                    Vue.set(this.filmstripImages, index, {
                        image: undefined,
                        segment: keyFrameSegment !== undefined ? parseInt(keyFrameSegment) : undefined,
                        imageStartTime: imageStartTime,
                        imageStartTimeHuman: this.giveDate(imageStartTime),
                        rgb: '#000'
                    });
                    Vue.set(this.filmstripRequests, index, {
                        dayCode: dayCode,
                        hourCode: hourCode,
                        segment: keyFrameSegment !== undefined ? parseInt(keyFrameSegment) : undefined,
                        filmstripId: this.currentFilmstripId
                    });
                    if (requestArray === undefined && addImage === true) {
                        requestArray = [];
                        for (let i = 1; i + index < this.totalImages; i++) {
                            this.getFilmStripImages(i + index, nextStartTime + ((i - 1) * (this.frameWidth) * this.timePerPixel), true, addImage, requestArray);
                        }
                    }
                    if (addImage === true) {
                        requestArray.push(this.filmstripRequests[index])
                        //once you reach the end of the new image requests
                        if (requestArray.length === 3) {
                            this.device.getKeyFrameImages(this.sourceId, requestArray, callback => {
                                if (callback !== undefined && callback.id === this.currentFilmstripId) {
                                    let index = this.filmstripImages.findIndex(obj => {
                                        //find the first one with no image and a valid unix time stamp
                                        return obj.image === undefined && obj.segment !== undefined;
                                    });
                                    let tempImage = new Image();
                                    tempImage.src = 'data:image/jpg;base64,' + callback.image;
                                    this.filmstripImages[index].image = tempImage;
                                }
                            });
                        }
                    }
                });
            }
        },
        async getAvailableSdCardTime(records) {
            this.availableFootageTimes = [];
            this.missingFootageTimes = [];
            let result = await records.map(record => {
                return {
                    startTime: new Date(record.starttime).valueOf(),
                    endTime: record.stoptime !== '' ? new Date(record.stoptime).valueOf() : new Date(record.starttime).valueOf() + 20000,
                    startTimeHuman: new Date(record.starttime).toLocaleString(),
                    endTimeHuman: record.stoptime !== '' ? new Date(record.stoptime).toLocaleString() : new Date(new Date(record.starttime).valueOf() + 20000).toLocaleString(),
                };
            });
            let sortedResults = await result.sort((a, b) => {
                if (a.startTime > b.startTime)
                    return 1;
                if (a.startTime < b.startTime)
                    return -1;
                return 0;
            });
            let count = 0;
            sortedResults.forEach(segment => {
                count++;
                this.availableFootageTimes.push({
                    startTime: segment.startTime,
                    endTime: segment.endTime,
                    startTimeHuman: this.giveDate(segment.startTime),
                    endTimeHuman: this.giveDate(segment.endTime)
                });
                if (count === sortedResults.length) {
                    this.retentionStart = this.availableFootageTimes[0].startTime;
                    this.retentionEnd = this.availableFootageTimes[this.availableFootageTimes.length - 1].endTime !== '' ? this.availableFootageTimes[this.availableFootageTimes.length - 1].endTime : this.availableFootageTimes[this.availableFootageTimes.length - 1].startTime + 20000;
                    let earliestDay = Math.floor(new Date(this.retentionStart).valueOf() / 86400000);
                    let latestDay = Math.floor(new Date(this.availableFootageTimes[this.availableFootageTimes.length - 1].endTime !== '' ? this.availableFootageTimes[this.availableFootageTimes.length - 1].endTime : this.availableFootageTimes[this.availableFootageTimes.length - 1].startTime + 20000).valueOf() / 86400000);
                    PubSub.publish('retention', (latestDay - earliestDay) + 1);
                    this.fetchingArchiveTimes = false;
                }
            });
        },
        getAvailableArchiveTime: debounce(function () {
            if (this.hasArchiveStream === false) {return;}
            if (this.fetchingArchiveTimes === true) {return;}
            this.fetchingArchiveTimes = true;
            //get all days we have footage
            this.device.getArchiveDays(this.sourceId, days => {
                if (days !== undefined) {
                    this.archiveDays = days;
                    PubSub.publish('retention', this.archiveDays.length);
                    let count = 0;
                    let minDay = null, maxDay = null;
                    //store the earliest and latest day
                    days.forEach(day => {
                        if (minDay === null && maxDay === null) {
                            minDay = day.dayCode;
                            maxDay = day.dayCode;
                        } else if (day.dayCode > maxDay) {
                            maxDay = day.dayCode;
                        } else if (day.dayCode < minDay) {
                            minDay = day.dayCode;
                        }
                        this.archiveHours[day.dayCode] = {};
                        //get all the hours for each day
                        this.device.getArchiveHours(this.sourceId, day.dayCode, hours => {
                            if (hours !== undefined) {
                                this.archiveHours[day.dayCode] = [];
                                hours.forEach(hour => {
                                    this.archiveHours[day.dayCode].push({
                                        unix: day.unix + hour * 3600 * 1000,
                                        hourCode: parseInt(hour)
                                    });
                                });
                                count++;
                                if (count === days.length && this.archiveHours[minDay]) {
                                    //grab the oldest hour from the oldest day
                                    let oldestHourAvailable = null, count3 = 0;
                                    this.archiveHours[minDay].forEach(hour => {
                                        if (oldestHourAvailable === null) {
                                            oldestHourAvailable = hour.hourCode;
                                        } else if (oldestHourAvailable > hour.hourCode) {
                                            oldestHourAvailable = hour.hourCode;
                                        }
                                        count3++;
                                        if (count3 === this.archiveHours[minDay].length) {
                                            this.device.getArchiveSegments(this.sourceId, minDay, oldestHourAvailable, segments => {
                                                if (segments !== undefined) {
                                                    this.retentionStart = parseInt(segments[0]);
                                                } else {
                                                    //console.error('error getting segments');
                                                }
                                            })
                                        }
                                    })
                                    //grab youngest hour from the youngest day
                                    let earliestHourAvailable = null, count4 = 0;
                                    this.archiveHours[maxDay].forEach(hour2 => {
                                        if (earliestHourAvailable === null) {
                                            earliestHourAvailable = hour2.hourCode;
                                        } else if (earliestHourAvailable < hour2.hourCode) {
                                            earliestHourAvailable = hour2.hourCode;
                                        }
                                        count4++;
                                        if (count4 === this.archiveHours[maxDay].length) {
                                            this.device.getArchiveSegments(this.sourceId, maxDay, earliestHourAvailable, segments => {
                                                //add the 20 seconds that are actually inside the segment itself
                                                this.retentionEnd = parseInt(segments[segments.length - 1]) + 20 * 1000;
                                            });
                                        }
                                    });
                                    let dayCodes = [], requestIdInfo = [];
                                    //get all the day codes for the archived footage
                                    let startDayCode = Math.floor(new Date(this.startBarTime - 21600 * 1000).valueOf() / 86400000);
                                    let endDayCode = Math.floor(new Date(this.endBarTime + 21600 * 1000).valueOf() / 86400000);
                                    for (let i = startDayCode; i !== endDayCode + 1; i++) {
                                        dayCodes.push(i);
                                    }
                                    //get all the hour codes inside objects for archived footage and the 6 before and after
                                    let startHourCode = new Date(this.startBarTime - 21600 * 1000).getUTCHours();
                                    let endHourCode = new Date(this.endBarTime + 21600 * 1000).getUTCHours();
                                    let dayIncrement = dayCodes.length - 1;
                                    //generate the requests to get segments
                                    for (let j = startHourCode; j < endHourCode + (dayIncrement * 24) + 1; j++) {
                                        if (j > 23) {
                                            requestIdInfo.push({
                                                dayCode: dayCodes[Math.floor(j / 24)],
                                                hourCode: j % 24
                                            });
                                        } else {
                                            requestIdInfo.push({dayCode: dayCodes[0], hourCode: j});
                                        }
                                    }
                                    let count = 0, count2 = 0, usedSegments = [];
                                    for (let k = 0; k < requestIdInfo.length; k++) {
                                        this.device.getArchiveSegments(this.sourceId, requestIdInfo[k].dayCode, requestIdInfo[k].hourCode, segments => {
                                            if (segments) {
                                                if (segments.length === 0) {
                                                    segments.push('0');
                                                }
                                                segments.forEach(async segment => {
                                                    if (segment > this.startBarTime - (21600 * 1000) - 19940 && segment < this.endBarTime + 21600 * 1000) {
                                                        usedSegments.push(parseInt(segment));
                                                    }
                                                    count++;
                                                    if (count === segments.length) {
                                                        count = 0;
                                                        count2++;
                                                        if (count2 === requestIdInfo.length) {
                                                            let sortedSegments = usedSegments.sort((a, b) => {
                                                                if (parseInt(a) < parseInt(b))
                                                                    return -1;
                                                                if (parseInt(a) > parseInt(b))
                                                                    return 1;
                                                                return 0;
                                                            });
                                                            if (sortedSegments.length === 0) {
                                                                sortedSegments.push('0');
                                                            }
                                                            this.missingFootageTimes = [];
                                                            this.availableFootageTimes = [];
                                                            let leftSegment = this.startBarTime - (21600 * 1000);
                                                            let rightSegment = sortedSegments[0];
                                                            let count5 = 0;
                                                            let goodTime = sortedSegments[0];
                                                            sortedSegments.forEach((segment, index) => {
                                                                count5++;
                                                                if (sortedSegments[index + 1] !== undefined) {
                                                                    leftSegment = segment;
                                                                    rightSegment = sortedSegments[index + 1];
                                                                    //if there is a gap larger than 30 seconds between two segments then the device might have stopped recording, and we split the availableFootageTimes
                                                                    if (leftSegment + 30 * 1000 < rightSegment && leftSegment > 172800000 && leftSegment !== rightSegment) {
                                                                        this.availableFootageTimes.push({
                                                                            startTime: goodTime,
                                                                            endTime: leftSegment + (20 * 1000),
                                                                            startTimeHuman: this.giveDate(goodTime),
                                                                            endTimeHuman: this.giveDate(leftSegment),
                                                                        });
                                                                        goodTime = rightSegment;
                                                                    }
                                                                }
                                                                if (count5 === sortedSegments.length) {
                                                                    leftSegment = sortedSegments[sortedSegments.length - 1];
                                                                    rightSegment = this.endBarTime + (21600 * 1000);
                                                                    this.availableFootageTimes.push({
                                                                        startTime: goodTime,
                                                                        endTime: leftSegment + (20 * 1000),
                                                                        startTimeHuman: this.giveDate(goodTime),
                                                                        endTimeHuman: this.giveDate(leftSegment + 20 * 1000),
                                                                    });
                                                                    this.availableFootageTimes.forEach((time, index) => {
                                                                        if (this.availableFootageTimes[index + 1] !== undefined) {
                                                                            this.missingFootageTimes.push({
                                                                                startTime: time.endTime,
                                                                                endTime: this.availableFootageTimes[index + 1].startTime,
                                                                                startTimeHuman: this.giveDate(time.endTime),
                                                                                endTimeHuman: this.giveDate(this.availableFootageTimes[index + 1].startTime),
                                                                            });
                                                                        }
                                                                    });
                                                                    this.fetchingArchiveTimes = false;
                                                                }
                                                            });
                                                        }
                                                    }
                                                });
                                            }
                                        });
                                    }
                                }
                            } else {
                                //console.error('error getting hours');
                            }
                        })
                    })
                } else {
                    //console.error('error getting days');
                }
            })
            this.getEventsList();
            this.getBookmarks();
        }, 1500),
        getEventsList() {
            PubSub.publish('getEventsList' + this.sourceId, {
                startTime: this.startBarTime - 21600 * 1000,
                endTime: this.endBarTime + 21600 * 1000
            });
        },
        getBookmarks() {
            PubSub.publish('getBookmarks' + this.sourceId);
        },
        createTimeLine() {
            this.cleanUp();
            let c = this.canvasElement;
            let ctx = c.getContext("2d", {
                alpha: false,
                willReadFrequently: true
            });

            //this chunk generates the filmstrip
            if (this.showFilmstrip === true) {
                let c2 = this.canvasFilmStripElement;
                let ctx2 = c2.getContext("2d");
                if (this.filmstripDone === true && this.movingFilmstrip === false) {
                    for (let filmIndex = 0; filmIndex < this.totalImages; filmIndex++) {
                        ctx2.beginPath();
                        if (this.filmstripImages[filmIndex]) {
                            if (this.filmstripImages[filmIndex].image && this.availableFootageTimes.length > 0 && this.availableFootageTimes[this.availableFootageTimes.length - 1].endTime > this.filmstripImages[filmIndex].imageStartTime) {
                                ctx2.drawImage(this.filmstripImages[filmIndex].image, this.convertTimeToLocation(this.filmstripImages[filmIndex].imageStartTime), 0, this.frameWidth, this.frameHeight);
                                ctx2.strokeStyle = 'black';
                                ctx2.lineWidth = 3;
                                ctx2.strokeRect(this.convertTimeToLocation(this.filmstripImages[filmIndex].imageStartTime), 0, this.frameWidth, this.frameHeight - 1);
                                if (filmIndex === this.selectedImageIndex) {
                                    ctx2.fillStyle = '#0009';
                                    ctx2.fillRect(this.convertTimeToLocation(this.filmstripImages[filmIndex].imageStartTime), 0, this.frameWidth, this.frameHeight);
                                }
                            } else {
                                ctx2.fillStyle = this.filmstripImages[filmIndex].rgb;
                                ctx2.fillRect(this.convertTimeToLocation(this.filmstripImages[filmIndex].imageStartTime), 0, this.frameWidth, this.frameHeight);

                                //this is debugging code for filmstrip to see info about each box
                                /*ctx2.font = 'bold ' + Math.floor(14) + "px Arial";
                                ctx2.fillStyle = "White";
                                ctx2.fillText(this.convertTimeToLocation(this.filmstripImages[filmIndex].imageStartTime), this.convertTimeToLocation(this.filmstripImages[filmIndex].imageStartTime), 20);
                                ctx2.fillText(filmIndex.toString(), this.convertTimeToLocation(this.filmstripImages[filmIndex].imageStartTime), 40);
                                ctx2.fillText(new Date(this.filmstripImages[filmIndex].imageStartTime).toLocaleTimeString(), this.convertTimeToLocation(this.filmstripImages[filmIndex].imageStartTime), 60);
                                ctx2.fillText(new Date(this.filmstripImages[filmIndex].segment).toLocaleTimeString(), this.convertTimeToLocation(this.filmstripImages[filmIndex].imageStartTime), 80);*/
                            }
                        }
                    }
                } else {
                    let text = 'Generating FilmStrip';
                    ctx2.beginPath();
                    ctx2.font = 'bold ' + Math.floor(this.canvasHeight * .23) + "px Arial";
                    ctx2.fillStyle = "White";
                    ctx2.fillText(text, (c2.offsetWidth - ctx2.measureText(text).width) / 2, (c2.offsetHeight + Math.floor(this.canvasHeight * .23)) / 2);
                }
            }

            //this fills in all the white blocks of where we have available footage
            if (this.availableFootageTimes.length !== 0) {
                ctx.beginPath();
                ctx.fillStyle = "#ffffff";
                this.availableFootageTimes.forEach(time => {
                    ctx.fillRect(this.convertTimeToLocation(time.startTime), 0, this.convertTimeToLocation(time.endTime) - this.convertTimeToLocation(time.startTime), this.canvasHeight);
                });
            }

            // This draws the time range of playback footage
            if (this.timeRangeStartTimeLocation !== this.timeRangeEndTimeLocation
                && this.timeRangeEndTimeLocation !== null && this.timeRangeStartTimeLocation !== null) {
                ctx.beginPath();
                ctx.fillStyle = "#bbbbbb";  // Timeline Background Color
                ctx.fillRect(
                    this.timeRangeStartTimeLocation,
                    0,
                    (this.timeRangeEndTimeLocation - this.timeRangeStartTimeLocation),
                    this.canvasHeight
                );

                // Find Begin and End Locations
                let startTime = Math.min(this.timeRangeStartTimeLocation, this.timeRangeEndTimeLocation);
                let endTime = Math.max(this.timeRangeStartTimeLocation, this.timeRangeEndTimeLocation);

                // Make Begin Drag Tab
                ctx.beginPath();
                ctx.moveTo(startTime, 0);
                ctx.fillStyle = "#000000";
                ctx.moveTo(startTime, 0);
                ctx.lineTo(startTime, this.canvasHeight);
                ctx.stroke();
                ctx.beginPath();
                ctx.lineTo(startTime - this.timeRangeDragTabs.width, 0);
                ctx.lineTo(startTime - this.timeRangeDragTabs.width, 13);
                ctx.lineTo(startTime, this.timeRangeDragTabs.height);
                ctx.lineTo(startTime, 0);
                ctx.fill();

                // Make End Drag Tab
                ctx.beginPath();
                ctx.moveTo(endTime, 0);
                ctx.fillStyle = "#000000";
                ctx.moveTo(endTime, 0);
                ctx.lineTo(endTime, this.canvasHeight);
                ctx.stroke();
                ctx.beginPath();
                ctx.lineTo(endTime + this.timeRangeDragTabs.width, 0);
                ctx.lineTo(endTime + this.timeRangeDragTabs.width, 13);
                ctx.lineTo(endTime, this.timeRangeDragTabs.height);
                ctx.lineTo(endTime, 0);
                ctx.fill();

                // Make Drag Tab Lines
                ctx.strokeStyle = "white";
                ctx.lineWidth = 1;
                [3, 7, 11].forEach(height => {

                    ctx.beginPath();
                    ctx.moveTo(startTime - 2, height);
                    ctx.lineTo(startTime - 11, height);
                    ctx.stroke();

                    ctx.beginPath();
                    ctx.moveTo(endTime + 2, height);
                    ctx.lineTo(endTime + 11, height);
                    ctx.stroke();

                });
                ctx.lineWidth = 5;
            }

            // This draws the currently fetched playback footage
            if (this.timeRangeStartTimeLocation !== this.timeRangeEndTimeLocation && this.timeRangeEndTimeLocation !== null && this.timeRangeStartTimeLocation !== null && this.frameBufferEndTime !== null) {
                ctx.beginPath();
                ctx.fillStyle = "#20a8d8";  // Highlighted Timeline Background Color
                ctx.fillRect(this.frameBufferStartLocation, 0, this.frameBufferEndLocation - this.frameBufferStartLocation, this.canvasHeight);
            }

            //This fills in the locations that you cannot click on the timeline
            if (this.missingFootageTimes.length !== 0) {
                ctx.beginPath();
                ctx.fillStyle = '#444444';
                this.missingFootageTimes.forEach(time => {
                    ctx.fillRect(this.convertTimeToLocation(time.startTime), 0, this.convertTimeToLocation(time.endTime) - this.convertTimeToLocation(time.startTime), this.canvasHeight);
                });
            }

            if (Object.keys(this.queuedSegments).length > 0) {
                for (const segmentKey of Object.keys(this.queuedSegments)) {
                    ctx.beginPath();
                    ctx.fillStyle = '#ffffff';
                    //see if there is another segment after the one you are looking at
                    this.queuedSegments[segmentKey].forEach((segment, index) => {
                        if (this.queuedSegments[segmentKey][index + 1] !== undefined) {
                            if (segment.endTime + 2000 < this.queuedSegments[segmentKey][index + 1].segment) {
                                ctx.fillRect(this.convertTimeToLocation(segment.endTime), 0, this.convertTimeToLocation(this.queuedSegments[segmentKey][index + 1].segment) - this.convertTimeToLocation(segment.endTime), this.canvasHeight);
                            }
                        }
                        if (index === this.queuedSegments[segmentKey].length - 1) {
                            if (this.queuedSegments[parseInt(segmentKey) + 1] !== undefined) {
                                if (this.queuedSegments[segmentKey][this.queuedSegments[segmentKey].length - 1].endTime + 2000 < this.queuedSegments[parseInt(segmentKey) + 1][0].segment) {
                                    ctx.fillRect(this.convertTimeToLocation(this.queuedSegments[segmentKey][this.queuedSegments[segmentKey].length - 1].endTime), 0, this.convertTimeToLocation(this.queuedSegments[parseInt(segmentKey) + 1][0].segment) - this.convertTimeToLocation(this.queuedSegments[segmentKey][this.queuedSegments[segmentKey].length - 1].endTime), this.canvasHeight);
                                }
                            }
                        }
                    });
                }
            }

            //This will display the events on the timeline
            if (this.eventsArray.length > 0) {
                this.eventsArray.forEach(event => {
                    ctx.beginPath();
                    switch (event.type) {
                        case 'Motion':
                            ctx.fillStyle = "red";
                            if (event.id === this.hoverEventId) {
                                ctx.fillRect(this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .03, this.convertTimeToLocation(event.endTime) - this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .19);
                            } else {
                                ctx.fillRect(this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .05, this.convertTimeToLocation(event.endTime) - this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .15);
                            }
                            break;
                        case 'Audio':
                            ctx.fillStyle = "green";
                            if (event.id === this.hoverEventId) {
                                ctx.fillRect(this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .23, this.convertTimeToLocation(event.endTime) - this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .19);
                            } else {
                                ctx.fillRect(this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .25, this.convertTimeToLocation(event.endTime) - this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .15);
                            }
                            break;
                        case 'Tamper':
                            ctx.fillStyle = "blue";
                            if (event.id === this.hoverEventId) {
                                ctx.fillRect(this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .43, this.convertTimeToLocation(event.endTime) - this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .19);
                            } else {
                                ctx.fillRect(this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .45, this.convertTimeToLocation(event.endTime) - this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .15);
                            }
                            break;
                        default:
                            ctx.fillStyle = "orange";
                            if (event.id === this.hoverEventId) {
                                ctx.fillRect(this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .63, this.convertTimeToLocation(event.endTime) - this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .19);
                            } else {
                                ctx.fillRect(this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .65, this.convertTimeToLocation(event.endTime) - this.convertTimeToLocation(event.timeStamp), this.canvasHeight * .15);
                            }
                    }
                    ctx.fill();
                });
            }

            // Gets access to canvas and draws a line through middle
            ctx.beginPath();
            if (this.isMobile === false) {
                ctx.lineWidth = 5;
            } else if (this.isMobile === true) {
                ctx.lineWidth = 3;
            }
            ctx.moveTo(0, this.canvasHeight * 0.95);
            ctx.lineTo(this.canvasWidth, this.canvasHeight * 0.95);
            ctx.strokeStyle = "black";
            ctx.stroke();
            // Draws the bars that represent nice points of time
            let timeIterator = this.referenceTime - (Math.ceil((this.referenceTimeLocation * this.timePerPixel) / this.tickInterval) * this.tickInterval) + this.startOffsetMS;
            let firstTickPos = (this.referenceTimeLocation - ((this.referenceTime - timeIterator) / this.timePerPixel));
            for (let i = firstTickPos; i < this.canvasWidth; i += this.pixelsPerTicks) {
                if ((timeIterator % this.barStepArray[this.stepPosition].millisecondPerBigTick) === 0) {
                    ctx.moveTo(i + 2, this.canvasHeight * .45);
                    ctx.lineTo(i + 2, this.canvasHeight * .95);
                    ctx.strokeStyle = "black";
                    ctx.stroke();
                    ctx.font = 'bold ' + Math.floor(this.canvasHeight * .23) + "px Arial";
                    ctx.fillStyle = "black";
                    ctx.fillText(this.convertTime(timeIterator), i, this.canvasHeight * .35);
                } else {
                    ctx.moveTo(i + 2, this.canvasHeight * .65);
                    ctx.lineTo(i + 2, this.canvasHeight * .95);
                    ctx.strokeStyle = "black";
                    ctx.stroke();
                }
                timeIterator += this.tickInterval;
            }

            //this displays all the books on the timeline that you would see
            if (this.bookmarksArray.length > 0) {
                this.bookmarksArray.forEach(bookmark => {
                    if (bookmark.time > this.startBarTime && bookmark.time < this.endBarTime) {
                        ctx.beginPath();
                        ctx.fillStyle = "#fc7f03"
                        ctx.moveTo(this.convertTimeToLocation(bookmark.time), 0);
                        ctx.lineTo(this.convertTimeToLocation(bookmark.time), this.canvasHeight * .25);
                        ctx.lineTo(this.convertTimeToLocation(bookmark.time) + this.canvasWidth * .005, this.canvasHeight * .20);
                        ctx.lineTo(this.convertTimeToLocation(bookmark.time) + this.canvasWidth * .01, this.canvasHeight * .25);
                        ctx.lineTo(this.convertTimeToLocation(bookmark.time) + this.canvasWidth * .01, 0);
                        ctx.fill();
                    }
                });
            }

            //this draws the upside down house on the red bar
            ctx.beginPath();
            ctx.fillStyle = "red";
            if (this.overRedBar === true && this.mouseDown === true) {
                ctx.moveTo(this.redBarLocation - 10, 0); // 1
                ctx.lineTo(this.redBarLocation + 10, 0);  // 2
                ctx.lineTo(this.redBarLocation + 10, this.canvasHeight * .11);  // 3
                ctx.lineTo(this.redBarLocation + 3, this.canvasHeight * .22);  // 4
                ctx.lineTo(this.redBarLocation + 3, this.canvasHeight);  // 5
                ctx.lineTo(this.redBarLocation - 3, this.canvasHeight);  // 6
                ctx.lineTo(this.redBarLocation - 3, this.canvasHeight * .22);  // 7
                ctx.lineTo(this.redBarLocation - 10, this.canvasHeight * .11);  // 8
                ctx.fill();
            } else {
                ctx.moveTo(this.redBarLocation - 8, 0); // 1
                ctx.lineTo(this.redBarLocation + 8, 0);  // 2
                ctx.lineTo(this.redBarLocation + 8, this.canvasHeight * .09);  // 3
                ctx.lineTo(this.redBarLocation + 2, this.canvasHeight * .20);  // 4
                ctx.lineTo(this.redBarLocation + 2, this.canvasHeight);  // 5
                ctx.lineTo(this.redBarLocation - 2, this.canvasHeight);  // 6
                ctx.lineTo(this.redBarLocation - 2, this.canvasHeight * .20);  // 7
                ctx.lineTo(this.redBarLocation - 8, this.canvasHeight * .09);  // 8
                ctx.fill();
            }
            //this chunk is used to display the reference time and location for debugging
            /*ctx.beginPath();
            ctx.strokeStyle = "green";
            ctx.moveTo(this.referenceTimeLocation, 0);
            ctx.lineTo(this.referenceTimeLocation, this.canvasHeight);
            ctx.stroke();
            ctx.font = 'bold ' + Math.floor(this.canvasHeight * .23) + "px Arial";
            ctx.fillStyle = "green";
            ctx.fillText(new Date(this.referenceTime).toLocaleTimeString(), this.referenceTimeLocation, this.canvasHeight * .55);*/
        },
        endRedBarScroll() {
            clearInterval(this.intervalRedBar);
            this.redBarLive = false;
        },
        endTimelineScroll() {
            clearInterval(this.intervalTimeLine);
            this.liveOnFlag = false;
            this.timelineMoving = false;
        },
        fingerDown(touchEvent) {
            this.currentlyScrolling = true;
            this.fingerDown1X = touchEvent.touches[0].pageX;
        },
        fingerMove(touchEvent) {
            this.endTimelineScroll();
            if (touchEvent.touches[0].pageX !== this.fingerDown1X) {
                this.referenceTimeLocation -= this.fingerDown1X - touchEvent.touches[0].pageX;
                if (this.timeRangeStartTime !== null && this.timeRangeEndTime !== null && this.selectingTimeRange === false) {
                    this.timeRangeStartTime -= (this.fingerDown1X - touchEvent.touches[0].pageX) * this.timePerPixel;
                    this.timeRangeEndTime -= (this.fingerDown1X - touchEvent.touches[0].pageX) * this.timePerPixel;
                }
                this.fingerDown1X = touchEvent.touches[0].pageX;
                this.refreshPage();
            }
        },
        fingerUp() {
            this.currentlyScrolling = false;
        },
        async goLive() {
            if (this.showFilmstrip === true) {
                await this.clearFilmStripImages();
            }
            this.endTimelineScroll();
            this.endRedBarScroll();
            this.isPlayingBack = false;
            this.selectingTimeRange = false;
            this.rewindBool = false;
            this.fastForwardValue = 1;
            this.timeRangeStartTime = this.timeRangeEndTime = null;
            this.referenceTimeLocation = this.canvasWidth * .80;
            this.intervalTimeLine = setInterval(this.playLive, REFRESH_INTERVAL);
            this.timelineMoving = true;
            this.liveOnFlag = true;
            if (this.redBarLive === false) {
                this.intervalRedBar = setInterval(this.redLive, REFRESH_INTERVAL);
            }
            if (this.storageLocation === External) {
                await this.getAvailableArchiveTime();
                this.startGetFilmStripTimout(0, this.startBarTime, true);
            } else if (this.storageLocation === SdCard) {
                this.device.getSdCardRecordings(this.sourceId);
            }
            if (this.playingLiveVideo === false) {
                this.switchingToLivestream = true;
                this.playingLiveVideo = true;
                await this.clearFrameBuffer();
                this.device.stopPlayback(this.sourceId);
                //this should destroy the previous archive player and default it to medium
                PubSub.publish('quality-change-' + this.sourceId, 1);
            }
        },
        async goPlayback(startTime, endTime, startLocation, timeRangeBool, overrideBool, filmstripSelected) {
            await this.setPlaybackVars(startTime, startLocation);
            if (filmstripSelected !== true) {
                this.createNewFilmstrip = true;
                await this.clearFilmStripImages();
            }
            if (timeRangeBool === true) {
                if (this.storageLocation === External) {
                    await this.createPlaybackRequest(startTime, endTime);
                    if (filmstripSelected !== true) {
                        this.startGetFilmStripTimout(0, this.startBarTime, true);
                    }
                } else if (this.storageLocation === SdCard) {
                    await this.startSdCardPlayback(startTime, endTime);
                }
            } else {
                if (this.storageLocation === External) {
                    await this.startPlayback(startTime, true);
                    if (filmstripSelected !== true) {
                        this.startGetFilmStripTimout(0, this.startBarTime, true);
                    }
                } else if (this.storageLocation === SdCard) {
                    await this.startSdCardPlayback(startTime);
                }
            }
        },
        getSdRecord(startTime) {
            return this.sdCardRecordings.find(record => {
                return new Date(record.starttime).valueOf() < startTime;
            })
        },
        async startSdCardPlayback(startTime, endTime) {
            if (endTime === undefined) {
                endTime = 0;
            }
            let record = this.sdCardRecordings.find(obj => {
                return new Date(obj.starttime).valueOf() < startTime;
            });
            await this.device.streamSdCardRequest(this.sourceId, startTime, endTime, record, request => {
                this.device.startSdPlayback(this.sourceId, request);
            });
        },
        startPlayback(startTime, firstIteration, previous) {
            //get the hour and day code of the hour after you start time just in case the next segment is in the follow range
            let dayCode = Math.floor(new Date(parseInt(startTime + (3600 * 1000))).valueOf() / 86400000);
            let hourCode = new Date(parseInt(startTime + (3600 * 1000))).getUTCHours();
            let requests = [];
            //put current hour and previous hour into request
            for (let i = 2; i > -1; i--) {
                if (hourCode > 1) {
                    requests.push({dayCode: dayCode, hourCode: hourCode - i});
                } else if (hourCode === 1) {
                    requests.push({dayCode: i === 2 ? dayCode - 1 : dayCode, hourCode: i === 2 ? 23 : hourCode - i});
                } else if (hourCode === 0) {
                    requests.push({dayCode: i > 0 ? dayCode - 1 : dayCode, hourCode: i === 2 ? 22 : i === 1 ? 23 : 0});
                }
                //wait until we finish creating all archive requests
                if (requests.length === 3) {
                    this.device.getArchiveSegments(this.sourceId, requests[1].dayCode, requests[1].hourCode, async segments => {
                        if (segments && segments.length !== 0) {
                            //if start time is less than the first segment in the present array
                            if (segments[0] > startTime) {
                                this.device.getArchiveSegments(this.sourceId, requests[0].dayCode, requests[0].hourCode, async segments => {
                                    //if it is less than the first segment, we know it needs to be the last segment of the past array
                                    let segment = {
                                        segment: parseInt(segments[segments.length - 1]),
                                        dayCode: requests[0].dayCode,
                                        hourCode: requests[0].hourCode
                                    };
                                    let request = await this.device.streamArchiveRequest(this.sourceId, [segment]);
                                    request = Object.assign({requestId: request}, {
                                        startTime: segment.segment,
                                        endTime: segment.segment + (25 * 1000),
                                        segment: segment.segment
                                    });
                                    if (firstIteration !== false) {
                                        this.sendPlaybackRequest(this.sourceId, request, true);
                                        //this.device.startPlayback(this.sourceId, request, true);
                                    } else {
                                        if (previous === true) {
                                            this.device.previousPlaybackSegment(this.sourceId, request, true);
                                        } else {
                                            this.device.nextPlaybackSegment(this.sourceId, request, true);
                                        }
                                    }
                                });
                            } else {
                                let index = segments.findIndex(_ => {
                                    return _ > startTime;
                                });
                                //if there is no segment greater than the start time in the present segment array
                                if (index === -1) {
                                    //if this is the first iteration of continuous playback, grab the last segment in the present array
                                    if (firstIteration !== false) {
                                        let segment = {
                                            segment: parseInt(segments[segments.length - 1]),
                                            dayCode: requests[1].dayCode,
                                            hourCode: requests[1].hourCode
                                        };
                                        let request = await this.device.streamArchiveRequest(this.sourceId, [segment]);
                                        request = Object.assign({requestId: request}, {
                                            startTime: startTime,
                                            endTime: startTime + (25 * 1000),
                                            segment: segment.segment
                                        });
                                        this.sendPlaybackRequest(this.sourceId, request, true);
                                        //this.device.startPlayback(this.sourceId, request, true);
                                    } else {
                                        //if this is not the first iteration, grab the first segment of the future array
                                        this.device.getArchiveSegments(this.sourceId, requests[2].dayCode, requests[2].hourCode, async segments => {
                                            //if it is less than the first segment, we know it needs to be the last segment of the past array
                                            let segment = {
                                                segment: parseInt(segments[0]),
                                                dayCode: requests[2].dayCode,
                                                hourCode: requests[2].hourCode
                                            };
                                            let request = await this.device.streamArchiveRequest(this.sourceId, [segment]);
                                            request = Object.assign({requestId: request}, {
                                                startTime: segment.segment,
                                                endTime: segment.segment + (25 * 1000),
                                                segment: segment.segment
                                            });
                                            if (previous === true) {
                                                this.device.previousPlaybackSegment(this.sourceId, request, true);
                                            } else {
                                                this.device.nextPlaybackSegment(this.sourceId, request, true);
                                            }
                                        });
                                    }
                                } else {
                                    //if we find a segment greater than our start time
                                    //if this is first iteration we grab the segment that we clicked inside
                                    if (firstIteration !== false) {
                                        let segment = {
                                            segment: parseInt(segments[index - 1]),
                                            dayCode: requests[1].dayCode,
                                            hourCode: requests[1].hourCode
                                        };
                                        let request = await this.device.streamArchiveRequest(this.sourceId, [segment]);
                                        request = Object.assign({requestId: request}, {
                                            startTime: startTime,
                                            endTime: startTime + (25 * 1000),
                                            segment: segment.segment
                                        });
                                        this.sendPlaybackRequest(this.sourceId, request, true);
                                        //this.device.startPlayback(this.sourceId, request, true);
                                    } else {
                                        //if this is not the first iteration, grab the segment greater than our start time
                                        let segment = {
                                            segment: parseInt(segments[index]),
                                            dayCode: requests[1].dayCode,
                                            hourCode: requests[1].hourCode
                                        };
                                        let request = await this.device.streamArchiveRequest(this.sourceId, [segment]);
                                        request = Object.assign({requestId: request}, {
                                            startTime: segment.segment,
                                            endTime: segment.segment + (25 * 1000),
                                            segment: segment.segment
                                        });
                                        if (previous === true) {
                                            this.device.previousPlaybackSegment(this.sourceId, request, true);
                                        } else {
                                            this.device.nextPlaybackSegment(this.sourceId, request, true);
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
            }
        },
        sendPlaybackRequest(sourceId, request, isContinuous) {
            PubSub.publish('startPlaybackRequest' + sourceId, {request: request, isContinuous: isContinuous});
        },
        async setPlaybackVars(startTime, startLocation, keepQueuedSegments) {
            this.rewindBool = false;
            this.fastForwardValue = 1;
            await this.clearFrameBuffer();
            await this.endTimelineScroll();
            await this.endRedBarScroll();
            this.isPlayingBack = true;
            await this.device.setTimeVar(this.sourceId, null);
            if (keepQueuedSegments !== true) {
                this.queuedSegments = {};
            }
            this.referenceTimeLocation = startLocation;
            this.redBarTime = this.referenceTime = startTime;
            this.redBarLive = false;
            this.playingLiveVideo = false;
            this.timelineMoving = true;
            this.intervalTimeLine = setInterval(this.playPlayback, REFRESH_INTERVAL);
            this.intervalRedBar = setInterval(this.redPlayback, REFRESH_INTERVAL);
            this.intervalFrameBuffer = setInterval(() => {
                this.frameBufferEndTime = this.device.getFrameBuffer(this.sourceId);
            }, REFRESH_INTERVAL);
            this.selectingTimeRange = false;
        },
        createPlaybackRequest(startTime, endTime) {
            this.queuedSegments = {};
            this.device.findArchivedSegments(this.sourceId, startTime, endTime, async sortedSegments => {
                let tempArray = [];
                let count = 0, count2 = 0;
                for (let j = 0; j < sortedSegments.length; j++) {
                    tempArray[count] = sortedSegments[j];
                    count++;
                    if (count === 180 || j + 1 === sortedSegments.length) {
                        this.queuedSegments[count2] = tempArray;
                        count2++;
                        count = 0;
                        tempArray = [];
                    }
                }
                if (Object.keys(this.queuedSegments).length > 0) {
                    let request = await this.device.streamArchiveRequest(this.sourceId, this.queuedSegments[0]);
                    request = Object.assign({requestId: request}, {
                        startTime: startTime,
                        endTime: endTime,
                        segment: this.queuedSegments[0]
                    });
                    this.device.startPlayback(this.sourceId, request, false);
                    this.frameBufferStartTime = startTime;
                    this.archiveStreaming = true;
                }
            });
        },
        async clearFrameBuffer() {
            await clearInterval(this.intervalFrameBuffer);
            await this.device.clearFrameBuffer(this.sourceId);
            this.frameBufferStartTime = this.frameBufferEndTime = null;
            this.refreshPage();
        },
        jumpToDate() {
            this.endTimelineScroll();
            //direction true if refTime greater, false if refTime lesser than selected date
            let direction = this.startBarTime > (new Date(this.jumpToDay).valueOf() + new Date().getTimezoneOffset() * 60 * 1000);
            let count = 0;
            let remainder = this.startBarTime % (86400 * 1000);
            let slide = setInterval(async () => {
                this.currentlySliding = true;
                if (direction === true) {
                    this.referenceTimeLocation += 100;
                } else {
                    this.referenceTimeLocation -= 100;
                }
                count++;
                this.refreshPage();
                if (count > 10) {
                    clearInterval(slide);
                    this.currentlySliding = false;
                    this.referenceTimeLocation = 0;
                    this.referenceTime = new Date(this.jumpToDay).valueOf() + remainder;
                    this.jumpToDay = '';
                    await this.clearFilmStripImages();
                    this.startGetFilmStripTimout(0, this.startBarTime, true);
                }
            }, 50)
            if (this.storageLocation === External) {
                this.getAvailableArchiveTime();
            } else if (this.storageLocation === SdCard) {
                this.device.getSdCardRecordings(this.sourceId);
            }
        },
        filmStripMouseMoved(mouseInfo) {
            this.currentMousePosX = mouseInfo.offsetX;
        },
        mouseMoved(mouseInfo) {
            this.currentMousePosX = mouseInfo.offsetX;
            this.currentMousePosY = mouseInfo.offsetY;
            if (mouseInfo.offsetY > this.canvasHeight * .25 && this.bookmarkElement) {
                if (this.bookmarkElement.style.display !== 'none') {
                    this.bookmarkElement.style.display = 'none';
                }
            } else {
                let bookmark = this.bookmarksArray.find(obj => {
                    return this.convertTimeToLocation(obj.time) < mouseInfo.offsetX &&
                        this.convertTimeToLocation(obj.time) + this.canvasWidth * .01 > mouseInfo.offsetX;
                });
                if (this.bookmarkElement) {
                    if (bookmark === undefined) {
                        this.bookmarkElement.style.display = "none";
                    } else {
                        this.bookmarkElement.style.top = this.canvasElement.offsetTop - 6 + "px";
                        this.bookmarkElement.style.left = this.canvasElement.offsetLeft + this.convertTimeToLocation(bookmark.time) + this.canvasWidth * .005 - this.bookmarkElement.offsetWidth / 2 + "px";
                        this.bookmarkElement.style.display = "block";
                        this.bookmarkInfoBoxText = bookmark.note;
                        this.bookmarkInfoBoxName = bookmark.user;
                        this.bookmarkInfoBoxTime = new Date(bookmark.time).toLocaleString();
                    }
                }
            }
            if (this.eventTextElement) {
                //find events that intersect with the mouse's current position
                let events = this.eventsArray.filter(event => {
                    if (event.timeStamp < this.mouseTime && event.endTime > this.mouseTime) {
                        return event
                    }
                })
                //if an event intersects the mouse's current position
                if (events.length !== 0) {
                    let count = 0;
                    events.forEach(event2 => {
                        if ((this.currentMousePosY > this.canvasHeight * .03 && this.currentMousePosY < this.canvasHeight * .21 && event2.type === 'Motion') ||
                            (this.currentMousePosY > this.canvasHeight * .23 && this.currentMousePosY < this.canvasHeight * .41 && event2.type === 'Audio') ||
                            (this.currentMousePosY > this.canvasHeight * .43 && this.currentMousePosY < this.canvasHeight * .61 && event2.type === 'Tamper') ||
                            (this.currentMousePosY > this.canvasHeight * .63 && this.currentMousePosY < this.canvasHeight * .81 && event2.type !== 'Motion' && event2.type !== 'Audio' && event2.type !== 'Tamper')) {
                            this.eventTextElement.style.top = this.canvasElement.offsetTop - 6 + "px";
                            this.eventTextElement.style.left = this.canvasElement.offsetLeft + this.convertTimeToLocation(this.mouseTime) + this.canvasWidth * .005 - this.eventTextElement.offsetWidth / 2 + "px";
                            this.eventTextElement.style.display = "block";
                            this.hoverEventId = event2.id;
                            this.eventTypeText = event2.type;
                            this.eventStartTime = new Date(event2.timeStamp).toLocaleTimeString();
                            this.eventEndTime = new Date(event2.endTime).toLocaleTimeString();
                        } else {
                            count++;
                        }
                        if (count === events.length) {
                            this.hoverEventId = null;
                            this.eventTextElement.style.display = "none";
                        }
                    });
                } else {
                    this.hoverEventId = null;
                    this.eventTextElement.style.display = "none";
                }
            }
        },
        mouseScrollWheel(scrollInfo) {
            if (this.currentlySliding === false) {
                if ((scrollInfo.deltaY < 0 && this.stepPosition > 0) || (scrollInfo.deltaY > 0 && this.stepPosition < 10)) {
                    if (this.showFilmstrip === true) {
                        this.clearFilmStripImages();
                    }
                    this.endTimelineScroll();
                    let oldTimeStart = this.timeRangeStartTime;
                    let oldTimeEnd = this.timeRangeEndTime;
                    this.referenceTime = this.referenceTime - ((this.referenceTimeLocation - this.currentMousePosX) * this.timePerPixel);
                    this.referenceTimeLocation = this.currentMousePosX;
                    if (this.timeRangeEndTime !== null && this.timeRangeStartTime !== null) {
                        this.timeRangeStartTime = this.referenceTime - (this.referenceTime - oldTimeStart);
                        this.timeRangeEndTime = this.referenceTime - (this.referenceTime - oldTimeEnd);
                    }
                    if (scrollInfo.deltaY < 0) {
                        this.stepPosition--;
                    } else if (scrollInfo.deltaY > 0) {
                        this.stepPosition++;
                    }
                    let count = 0;
                    if (this.currentlyScaling === false) {
                        this.currentlyScaling = true;
                        let scale = setInterval(() => {
                            if (scrollInfo.deltaY < 0) {
                                this.globalScaleFactor += 2;
                            } else if (scrollInfo.deltaY > 0) {
                                this.globalScaleFactor -= 1;
                            }
                            count++
                            if (count === 15 || this.globalScaleFactor === 1) {
                                clearInterval(scale);
                                this.globalScaleFactor = this.barStepArray[this.stepPosition].scaleFactor;
                                if (this.timeRangeEndTime !== null && this.timeRangeStartTime !== null) {
                                    this.timeRangeStartTime = this.referenceTime - (this.referenceTime - oldTimeStart);
                                    this.timeRangeEndTime = this.referenceTime - (this.referenceTime - oldTimeEnd);
                                }
                                this.currentlyScaling = false;
                                if (this.showFilmstrip === true) {
                                    this.startGetFilmStripTimout(0, this.startBarTime, true);
                                }
                            }
                        }, 20);
                    }
                }
                this.refreshPage();
                if (this.storageLocation === External) {
                    this.getAvailableArchiveTime();
                } else if (this.storageLocation === SdCard) {
                    this.device.getSdCardRecordings(this.sourceId);
                }
            }
        },
        playLive() {
            this.referenceTime = Date.now();
        },
        playPlayback() {
            if (this.device.getTime(this.sourceId) !== null) {
                this.referenceTime = this.device.getTime(this.sourceId);
            }
        },
        redLive() {
            this.redBarTime = this.currentTime;
            this.redBarLive = true;
            this.refreshPage();
        },
        redPlayback() {
            if (this.device.getTime(this.sourceId) !== null) {
                this.redBarTime = this.device.getTime(this.sourceId);
            }
            this.refreshPage();
        },
        refreshPage() {
            this.cleanUp();
            this.createTimeLine();
        },
        selectTimeRange() {
            this.endTimelineScroll();
        },
        async setup(isResize) {
            //this sets up the dimensions of the canvas
            if (this.canvasElement) {
                let height;
                let container = document.getElementById('stream_settings_container_' + this.sourceId);
                if (container !== null) {
                    let width = container.clientWidth;
                    if (this.isMobile) {
                        height = container.clientHeight * 0.1;
                    } else {
                        height = container.clientHeight * 0.08;
                    }
                    this.canvasElement.style.width = width + 'px'; // width of canvas
                    this.canvasElement.style.height = height + 'px'; // height of canvas
                    this.canvasWidth = width; // number of pixels in width
                    this.canvasHeight = height; // number of pixels in height
                    this.frameHeight = height;
                    this.frameWidth = height * (16 / 9);
                    this.blowupHeight = height * 4;
                    this.blowupWidth = height * 4 * (16 / 9);
                    this.timelineRollOver = width * .80;
                    await this.clearFilmStripImages();
                    this.startGetFilmStripTimout(0, this.startBarTime, true);
                    //create the timeline after getting the dimensions of the window
                    if (!isResize) {
                        Vue.nextTick().then(async () => {
                            this.createTimeLine();
                            await this.device.connectToArchive(this.sourceId);
                        });
                        await this.goLive();
                    }
                }
            }
        },
        convertTimeToLocation(time) {
            //converts a unix timestamp to a relative location on the timeline
            return (this.referenceTimeLocation - ((this.referenceTime - parseInt(time)) / this.timePerPixel));
        },
        convertLocationToTime(location) {
            //converts a relative location on the timeline to a unix timestamp
            return Math.floor((this.referenceTime - ((this.referenceTimeLocation - location) * this.timePerPixel)));
        },
        zoomInOut(zoom) {
            let oldTimeStart = this.timeRangeStartTime;
            let oldTimeEnd = this.timeRangeEndTime;
            this.referenceTime = this.redBarTime;
            this.referenceTimeLocation = this.redBarLocation;
            if (zoom === 1 || zoom === -1) {
                if (this.timeRangeEndTime !== null && this.timeRangeStartTime !== null) {
                    this.timeRangeStartTime = this.referenceTime - (this.referenceTime - oldTimeStart);
                    this.timeRangeEndTime = this.reference - (this.referenceTime - oldTimeEnd);
                }
                if (zoom === -1) {
                    this.stepPosition--;
                } else if (zoom === 1) {
                    this.stepPosition++;
                }
                let count = 0;
                if (this.currentlyScaling === false) {
                    this.currentlyScaling = true;
                    let scale = setInterval(() => {
                        if (zoom === -1) {
                            this.globalScaleFactor += 2;
                        } else if (zoom === 1) {
                            this.globalScaleFactor -= 1;
                        }
                        count++
                        if (count === 15 || this.globalScaleFactor === 1) {
                            this.currentlyScaling = false;
                            this.globalScaleFactor = this.barStepArray[this.stepPosition].scaleFactor;
                            clearInterval(scale);
                            if (this.timeRangeEndTime !== null && this.timeRangeStartTime !== null) {
                                this.timeRangeStartTime = this.referenceTime - (this.referenceTime - oldTimeStart);
                                this.timeRangeEndTime = this.referenceTime - (this.referenceTime - oldTimeEnd);
                            }
                        }
                    }, 20)
                }
            }
            this.refreshPage();
        },
        getTextWidth(text) {
            let canvas = document.createElement('canvas');
            let c = canvas.getContext('2d');
            c.font = '22px Arial';
            let textDimensions = c.measureText(text);
            return textDimensions.width;
        }
    },

    computed: {
        canvasElement() {
            return document.getElementById('canvas_' + this.sourceId) || null;
        },
        canvasFilmStripElement() {
            return document.getElementById('canvas_filmstrip_' + this.sourceId) || null;
        },
        bookmarkElement() {
            return document.getElementById("bookmark" + this.sourceId) || null;
        },
        eventTextElement() {
            return document.getElementById("event" + this.sourceId) || null;
        },
        totalImages() {
            return Math.ceil((this.canvasWidth / this.frameWidth) + Math.floor(8 - this.stepPosition / 4));
        },
        minDateSelection() {
            return new Date(this.pastTime);
        },
        timeRangeStartTimeLocation() {
            if (this.timeRangeStartTime !== null) {
                return this.convertTimeToLocation(this.timeRangeStartTime);
            }
        },
        timeRangeEndTimeLocation() {
            if (this.timeRangeEndTime !== null) {
                return this.convertTimeToLocation(this.timeRangeEndTime);
            }
        },
        frameBufferEndLocation() {
            if (this.frameBufferEndTime !== null) {
                return this.convertTimeToLocation(this.frameBufferEndTime);
            }
        },
        frameBufferStartLocation() {
            if (this.frameBufferStartTime !== null) {
                return this.convertTimeToLocation(this.frameBufferStartTime);
            }
        },
        mouseTime() {
            return this.convertLocationToTime(this.currentMousePosX);
        },
        mouseTimeHuman() {
            return this.giveDate(this.convertLocationToTime(this.currentMousePosX));
        },
        timePerPixel() {
            //ms / pixel    should have a tick every number of pixels this is divided by
            if (this.isMobile === false) {
                return this.tickInterval / this.globalScaleFactor;
            } else {
                return this.tickInterval / (this.globalScaleFactor / 2.25);
            }
        },
        pixelsPerTicks() {
            //pixel / tick
            return this.tickInterval / this.timePerPixel;
        },
        startOffsetMS() {
            //ms before the first bar
            return (this.tickInterval - (this.referenceTime % this.tickInterval));
        },
        tickInterval() {
            //ms / bar
            return this.barStepArray[this.stepPosition].millisecondPerTick;
        },
        startBarTime() {
            //time at the beginning of the timeline
            return this.convertLocationToTime(0);
        },
        endBarTime() {
            //time at the end of the timeline
            return Math.floor(this.startBarTime + this.canvasWidth * this.timePerPixel);
        },
        redBarLocation() {
            return this.convertTimeToLocation(this.redBarTime);
        },
        overRedBar() {
            return this.currentMousePosX < (this.redBarLocation + 10) && this.currentMousePosX > (this.redBarLocation - 10) && this.hasArchiveStream === true;
        },
        overBeginDragTag() {
            if (this.isPlayingBack && this.mouseDown) {
                let xRightBound = this.currentMousePosX < this.timeRangeStartTimeLocation;
                let xLeftBound = this.currentMousePosX > (this.timeRangeStartTimeLocation - this.timeRangeDragTabs.width);
                let yRightBound = this.currentMousePosY < this.timeRangeDragTabs.height;
                let yLeftBound = this.currentMousePosY > 0;
                return xRightBound && xLeftBound && yRightBound && yLeftBound;
            }
            return false;
        },
        overEndDragTag() {
            if (this.isPlayingBack && this.mouseDown) {
                let xRightBound = this.currentMousePosX < (this.timeRangeEndTimeLocation + this.timeRangeDragTabs.width);
                let xLeftBound = this.currentMousePosX > this.timeRangeEndTimeLocation;
                let yRightBound = this.currentMousePosY < this.timeRangeDragTabs.height;
                let yLeftBound = this.currentMousePosY > 0;
                return xRightBound && xLeftBound && yRightBound && yLeftBound;
            }
            return false;
        },
        insideTimeRange() {
            return (this.mouseTime > this.timeRangeStartTime && this.mouseTime < this.timeRangeEndTime);
        },
        insideTimeBuffer() {
            return (this.mouseTime > this.timeRangeStartTime && this.mouseTime < this.frameBufferEndTime);
        },
        dragPastRollOver() {
            return (this.redBarLocation < 0 || this.redBarLocation > this.canvasWidth * .81);
        },
        futureTime() {
            return this.retentionEnd;
        },
        pastTime() {
            return this.retentionStart;
        },
        redBarHuman() {
            return new Date(this.redBarTime);
        },
        realTimeHuman() {
            return new Date(this.referenceTime);
        },
        referenceTimeHuman() {
            return this.giveDate(this.referenceTime);
        },
        startBarTimeHuman() {
            return this.giveDate(this.convertLocationToTime(0));
        },
        endBarTimeHuman() {
            return this.giveDate(this.startBarTime + this.canvasWidth * this.timePerPixel);
        },
        frameBufferStartTimeHuman() {
            return this.giveDate(this.frameBufferStartTime);
        },
        sourceId() {
            return this.device.getDeviceId() + this.sourceToken + this.dataChannel;
        },
        filmStripDistanceTraveled() {
            if (this.filmstripImages[0]) {
                return this.convertTimeToLocation(this.startBarTime) - this.convertTimeToLocation(this.filmstripImages[0].imageStartTime);
            }
            return null;
        }
    },

    watch: {
        storageLocation() {
            if (this.storageLocation === SdCard) {
                this.showFilmstrip = false;
            }
        },
        filmStripDistanceTraveled() {
            if (this.filmStripDistanceTraveled > this.frameWidth * 4 && this.createNewFilmstrip === false && this.switchingToLivestream === false) {
                this.cyclingFilmstrip = true;
                this.cycleFilmStripImages();
            }
        },
        filmstripImages: {
            deep: true,
            handler() {
                this.filmstripDone = (((this.filmstripImages.length >= (this.totalImages - 4)) &&
                    (this.filmstripImages.findIndex(image => {
                        return image === undefined;
                    }) === -1)) || this.cyclingFilmstrip === true);
            }
        },
        filmstripRequests: {
            deep: true,
            handler() {
                if (this.createNewFilmstrip === true) {
                    let requestReady = (((this.filmstripRequests.length >= (this.totalImages - 4)) &&
                        (this.filmstripRequests.findIndex(image => {
                            return image === undefined;
                        }) === -1)) || this.cyclingFilmstrip === true);
                    if (requestReady === true) {
                        this.createNewFilmstrip = false;
                        this.device.getKeyFrameImages(this.sourceId, this.filmstripRequests, callback => {
                            if (callback !== undefined && callback.id === this.currentFilmstripId) {
                                let index = this.filmstripImages.findIndex(obj => {
                                    //find the first one with no image and a valid unix time stamp
                                    return obj.image === undefined && obj.segment !== undefined;
                                });
                                let tempImage = new Image();
                                tempImage.src = 'data:image/jpg;base64,' + callback.image;
                                this.filmstripImages[index].image = tempImage;
                            }
                        });
                    }
                }
            }
        },
        isPlayingBack() {
            PubSub.publish('isPlayingBack' + this.sourceId, this.isPlayingBack);
        },
        redBarLocation() {
            if (this.redBarLocation > this.timelineRollOver && this.playingLiveVideo === false && this.currentlyScrolling === false && this.dragPastRollOver === false && this.currentlyScaling === false && this.draggingRedBar === false) {
                let redTime = this.redBarTime;
                this.currentlySliding = true;
                let slide = setInterval(async () => {
                    this.referenceTimeLocation -= this.canvasWidth * .1;
                    this.referenceTime -= this.timePerPixel * 100;
                    this.refreshPage();
                    if (this.referenceTimeLocation < this.canvasWidth * .1) {
                        clearInterval(slide);
                        this.currentlySliding = false;
                        this.referenceTimeLocation = this.canvasWidth * .1;
                        this.referenceTime = redTime;
                        await this.clearFilmStripImages();
                        this.startGetFilmStripTimout(0, this.startBarTime, true);
                    }
                }, 50);
            }
        },
        overTimeline() {
            if (this.overTimeline === true) {
                this.intervalOverTimeline = setInterval(() => {
                    this.overTimelineDisplay = this.mouseTimeHuman.toLocaleString();
                    if (this.$refs.timelineTimeStamp && this.$refs.timeline) {
                        let eleWidth = this.$refs.timelineTimeStamp.offsetWidth > 0 ? this.$refs.timelineTimeStamp.offsetWidth + 1 : this.getTextWidth(this.overTimelineDisplay);
                        let offset = this.$refs.timeline.offsetLeft;
                        if (this.currentMousePosX <= eleWidth / 2) {
                            this.overTimelineStyle = offset;
                        } else if (this.currentMousePosX >= this.canvasWidth - (eleWidth / 2)) {
                            this.overTimelineStyle = this.canvasWidth - eleWidth + offset;
                        } else {
                            this.overTimelineStyle = this.currentMousePosX - (eleWidth / 2) + offset;
                        }
                    }
                }, REFRESH_INTERVAL);
            } else {
                clearInterval(this.intervalOverTimeline);
            }
        },
        overFilmStrip() {
            if (this.overFilmStrip === true) {
                this.intervalOverFilmstrip = setInterval(() => {
                    if (this.$refs.timelineTimeStamp && this.$refs.timeline) {
                        let eleWidth = this.$refs.timelineTimeStamp.offsetWidth + 1;
                        let offset = this.$refs.timeline.offsetLeft;
                        if (this.currentMousePosX <= this.blowupWidth / 2) {
                            this.overTimelineStyle = offset + this.blowupWidth / 2 - eleWidth / 2;
                        } else if (this.currentMousePosX >= this.canvasWidth - (this.blowupWidth / 2)) {
                            this.overTimelineStyle = this.canvasWidth - this.blowupWidth / 2 - eleWidth / 2 + offset;
                        } else {
                            this.overTimelineStyle = this.currentMousePosX - (eleWidth / 2) + offset;
                        }
                    }
                    if (this.filmstripImages[this.selectedImageIndex]) {
                        this.overTimelineDisplay = new Date(parseInt(this.filmstripImages[this.selectedImageIndex].imageStartTime)).toLocaleString();
                    } else {
                        this.overTimelineDisplay = '';
                    }
                }, REFRESH_INTERVAL);
            } else {
                clearInterval(this.intervalOverFilmstrip);
            }
        }
    }
}
</script>

<style scoped>
.boxHover {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    cursor: default;
    border-radius: 15px;
    max-width: 200px;
    background-color: #303030;
    z-index: 5;
    padding: 8px;
    border-color: #222222;
    border-style: solid;
    border-width: 3px;
    transform: translateY(-100%);
    word-break: break-word;
}
</style>